<template>
  <div class="row v-application" data-app>
    <div class="col-md-12">
      <div class="row mb-40">
        <div class="col-md-12">
          <div class="d-flex align-items-center">
            <h3>Antecedentes Personales</h3>
            <span class="uc-heading-decoration"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="uc-form-group">
        <label for="rut">RUT</label>
        <input
          v-model="rut"
          v-mask="mask.rut"
          id="rut"
          @change="
            validateRut(rut);
            setField({ id: 'rut', value: rut });
          "
          type="text"
          :class="`uc-input-style w-icon-left ${required.rut}`"
          @keydown="clearRequired('rut')"
          v-tooltip="requiredTooltip.rut"
          placeholder="RUT"
        />
        <i class="uc-icon">recent_actors</i>
      </div>
    </div>
    <div class="col-md-12">
      <div class="uc-form-group">
        <label for="name">Nombre Completo</label>
        <input
          :class="`uc-input-style w-icon-left ${required.name}`"
          @change="setField({ id: 'name', value: name })"
          @keydown="clearRequired('name')"
          id="name"
          placeholder="Nombre Completo"
          type="text"
          v-model="name"
          v-tooltip="requiredTooltip.name"
        />
        <i class="uc-icon">account_box</i>
      </div>
    </div>
    <div class="col-md-12">
      <div class="uc-form-group">
        <v-menu
          :close-on-content-click="false"
          min-width="290px"
          offset-y
          ref="birthMenu"
          v-model="birthMenu"
        >
          <template v-slot:activator="{ on, attrs }">
            <div>
              <label for="birth">Fecha de Nacimiento</label>
              <input
                :class="`uc-input-style w-icon-left ${required.birth}`"
                id="birth"
                readonly
                type="text"
                v-bind="attrs"
                v-model="birthParsed"
                v-on="on"
              />
              <i class="uc-icon">account_box</i>
            </div>
          </template>
          <v-date-picker
            :first-day-of-week="1"
            :max="new Date().toISOString().substr(0, 10)"
            @change="setField({ id: 'birth', value: birth })"
            min="1950-01-01"
            no-title
            ref="picker"
            v-model="birth"
          ></v-date-picker>
        </v-menu>
      </div>
    </div>
    <div class="col-md-12">
      <div class="uc-form-group">
        <label for="email">Correo</label>
        <input
          v-model="email"
          id="email"
          @change="setField({ id: 'email', value: email })"
          type="text"
          :class="`uc-input-style w-icon-left ${required.email}`"
          @keydown="clearRequired('email')"
          v-tooltip="requiredTooltip.email"
          placeholder="Correo"
        />
        <i class="uc-icon">contact_mail</i>
      </div>
    </div>
    <div class="col-md-12">
      <div class="uc-form-group">
        <label for="phone">Número Telefónico</label>
        <input
          v-model="phone"
          v-mask="'+56 # #### ####'"
          id="phone"
          @change="setField({ id: 'phone', value: phone })"
          type="text"
          :class="`uc-input-style w-icon-left ${required.phone}`"
          @keydown="clearRequired('phone')"
          v-tooltip="requiredTooltip.phone"
          placeholder="+56 9 1234 5678"
        />
        <i class="uc-icon">phone_iphone</i>
      </div>
    </div>
    <div class="col-md-12">
      <div class="uc-form-group">
        <label for="region">Región</label>
        <v-select
          :class="`uc-input-style w-icon-left ${required.region}`"
          :options="regionsList"
          :reduce="(region) => region.id"
          @input="
            setField({ id: 'region', value: region });
            clearRequired('region');
          "
          id="region"
          label="name"
          placeholder="Región"
          v-model="region"
        >
          <span slot="no-options">Búsqueda no encontrada...</span>
        </v-select>
        <i class="uc-icon">school</i>
      </div>
    </div>
    <div class="col-md-12">
      <div class="uc-form-group">
        <label for="city">Ciudad</label>
        <input
          v-model="city"
          id="city"
          @change="setField({ id: 'city', value: city })"
          type="text"
          :class="`uc-input-style w-icon-left ${required.city}`"
          @keydown="clearRequired('city')"
          v-tooltip="requiredTooltip.city"
          placeholder="Santiago"
        />
        <i class="uc-icon">map</i>
      </div>
    </div>
    <div class="col-md-12">
      <div class="row mb-40">
        <div class="col-md-12">
          <div class="d-flex align-items-center">
            <h3>Antecedentes Personales</h3>
            <span class="uc-heading-decoration"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="uc-form-group">
        <label for="university">Universidad</label>
        <v-select
          :class="`uc-input-style w-icon-left ${required.university}`"
          :create-option="
            (university) => ({ id: university, name: university })
          "
          :options="universitiesList"
          :reduce="(university) => university.id"
          @input="
            setField({ id: 'university', value: university });
            clearRequired('university');
          "
          id="university"
          label="name"
          placeholder="Universidad"
          push-tags
          taggable
          v-model="university"
          v-tooltip="
            `Si tu universidad no está en el listado, sólo ecríbela y presiona enter`
          "
        ></v-select>
        <i class="uc-icon">school</i>
      </div>
    </div>
    <div class="col-md-12">
      <div class="uc-form-group">
        <label for="condition">Situación Actual</label>
        <v-select
          :class="`uc-input-style w-icon-left ${required.condition}`"
          :options="conditionsList"
          :reduce="(condition) => condition.id"
          @input="
            setField({ id: 'condition', value: condition });
            clearRequired('condition');
          "
          id="condition"
          label="name"
          placeholder="Condición"
          v-model="condition"
          v-tooltip="requiredTooltip.condition"
        >
          <span slot="no-options">Búsqueda no encontrada...</span>
        </v-select>
        <i class="uc-icon">work</i>
      </div>
    </div>
    <div class="col-md-12" v-if="condition && condition != 8">
      <div class="uc-form-group">
        <label for="score">Promedio</label>
        <input
          :class="`uc-input-style w-icon-left ${required.score}`"
          :placeholder="Math.round((Math.random() * (7 - 4) + 4) * 100) / 100"
          @change="setField({ id: 'score', value: score })"
          @keydown="clearRequired('score')"
          id="score"
          type="text"
          v-mask="'#.##'"
          v-model="score"
          v-tooltip="requiredTooltip.score"
        />
        <i class="uc-icon">contact_mail</i>
      </div>
    </div>
    <div class="col-md-12" v-if="condition == 8">
      <div class="uc-form-group">
        <label for="ranking">Posición Ranking de Egreso</label>
        <input
          v-model="ranking"
          id="ranking"
          @change="setField({ id: 'ranking', value: ranking })"
          type="text"
          :class="`uc-input-style w-icon-left ${required.ranking}`"
          @keydown="clearRequired('ranking')"
          v-tooltip="requiredTooltip.ranking"
          :placeholder="Math.floor(Math.random() * (10 - 1) + 1)"
        />
        <i class="uc-icon">contact_mail</i>
      </div>
    </div>

    <div class="col-md-12 mb-20">
      <div class="uc-form-group">
        <label for="assistantship">¿Ayudantías?</label>
        <i class="uc-icon ml-4"></i>
      </div>
      <div class="uc-form-group uc-radio-group">
        <input
          type="radio"
          id="assistantship_1"
          name="assistantship"
          value="1"
          class="uc-orig-radio"
          v-model="hasAssistantship"
          :checked="hasAssistantship == 1"
        />
        <label for="assistantship_1" class="uc-radio-button">Sí</label>
        <input
          type="radio"
          id="assistantship_2"
          name="assistantship"
          value
          class="uc-orig-radio"
          v-model="hasAssistantship"
          :checked="hasAssistantship"
        />
        <label for="assistantship_2" class="uc-radio-button">No</label>
      </div>
    </div>
    <Assistantship
      v-if="hasAssistantship"
      :assistantshipContent="firstAssistantshipsContent"
    />

    <div class="col-md-6 mb-20" v-if="hasAssistantship">
      <div class="uc-form-group">
        <button
          style
          class="uc-btn btn-primary"
          @click="addElement(assistantships, 'assistantship')"
        >
          Añadir Otra Ayudantía
          <i class="uc-icon icon-shape--rounded ml-4">add</i>
        </button>
      </div>
    </div>

    <Assistantship
      v-for="(assistantship, key) in assistantships"
      :key="key"
      :assistantshipKey="parseInt(key.match(/\d+/)[0])"
      :assistantshipContent="assistantship"
    />

    <div class="col-md-12">
      <div class="row my-40">
        <div class="col-md-12">
          <div class="d-flex align-items-center">
            <h3>Estudios Postgrado</h3>
            <span class="uc-heading-decoration"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 mb-20">
      <div class="uc-form-group">
        <label for="diploma">¿Diplomado?</label>
        <i class="uc-icon ml-4"></i>
      </div>
      <div class="uc-form-group uc-radio-group">
        <input
          type="radio"
          id="diploma_1"
          name="diploma"
          value="1"
          class="uc-orig-radio"
          v-model="hasDiploma"
          :checked="hasDiploma == 1"
        />
        <label for="diploma_1" class="uc-radio-button">Sí</label>
        <input
          type="radio"
          id="diploma_2"
          name="diploma"
          value
          class="uc-orig-radio"
          v-model="hasDiploma"
          :checked="hasDiploma"
        />
        <label for="diploma_2" class="uc-radio-button">No</label>
      </div>
    </div>
    <Diploma
      v-show="hasDiploma"
      :universitiesList="universitiesList"
      :diplomaContent="firstDiplomaContent"
    />
    <div class="col-md-6 mb-20" v-if="hasDiploma">
      <div class="uc-form-group">
        <button
          style
          class="uc-btn btn-primary"
          @click="addElement(diplomas, 'diploma')"
        >
          Añadir Otro Diplomado
          <i class="uc-icon icon-shape--rounded ml-4">add</i>
        </button>
      </div>
    </div>
    <Diploma
      v-for="(diploma, key) in diplomas"
      :key="key"
      :diplomaKey="parseInt(key.match(/\d+/)[0])"
      :diplomaContent="diploma"
      :universitiesList="universitiesList"
    />

    <div class="col-md-12 mb-20">
      <div class="uc-form-group">
        <label for="master">¿Magíster?</label>
        <i class="uc-icon ml-4"></i>
      </div>
      <div class="uc-form-group uc-radio-group">
        <input
          type="radio"
          id="master_1"
          name="master"
          value="1"
          class="uc-orig-radio"
          :checked="hasMaster == 1"
          v-model="hasMaster"
        />
        <label for="master_1" class="uc-radio-button">Sí</label>
        <input
          type="radio"
          id="master_2"
          name="master"
          value
          class="uc-orig-radio"
          :checked="hasMaster"
          v-model="hasMaster"
        />
        <label for="master_2" class="uc-radio-button">No</label>
      </div>
    </div>
    <Master
      v-show="hasMaster"
      :universitiesList="universitiesList"
      :masterContent="firstMasterContent"
    />
    <div class="col-md-6 mb-20" v-if="hasMaster">
      <div class="uc-form-group">
        <button
          style
          class="uc-btn btn-primary"
          @click="addElement(masters, 'master')"
        >
          Añadir Otro Magíster
          <i class="uc-icon icon-shape--rounded ml-4">add</i>
        </button>
      </div>
    </div>
    <Master
      v-for="(master, key) in masters"
      :key="key"
      :masterKey="parseInt(key.match(/\d+/)[0])"
      :masterContent="master"
      :universitiesList="universitiesList"
    />

    <div class="col-md-12 mb-20">
      <div class="uc-form-group">
        <label for="phd">¿Doctorado?</label>
        <i class="uc-icon ml-4"></i>
      </div>
      <div class="uc-form-group uc-radio-group">
        <input
          type="radio"
          id="phd_1"
          name="phd"
          value="1"
          class="uc-orig-radio"
          :checked="hasPhd == 1"
          v-model="hasPhd"
        />
        <label for="phd_1" class="uc-radio-button">Sí</label>
        <input
          type="radio"
          id="phd_2"
          name="phd"
          value
          class="uc-orig-radio"
          :checked="hasPhd"
          v-model="hasPhd"
        />
        <label for="phd_2" class="uc-radio-button">No</label>
      </div>
    </div>
    <Phd
      v-show="hasPhd"
      :universitiesList="universitiesList"
      :phdContent="firstPhdContent"
    />
    <div class="col-md-6 mb-20" v-if="hasPhd">
      <div class="uc-form-group">
        <button
          style
          class="uc-btn btn-primary"
          @click="addElement(phds, 'phd')"
        >
          Añadir Otro Doctorado
          <i class="uc-icon icon-shape--rounded ml-4">add</i>
        </button>
      </div>
    </div>
    <Phd
      v-for="(phd, key) in phds"
      :key="key"
      :phdKey="parseInt(key.match(/\d+/)[0])"
      :phdContent="phd"
      :universitiesList="universitiesList"
    />

    <div class="col-md-12">
      <div class="uc-form-group">
        <label for="areas">Áreas del Derecho de Interés</label>
        <v-select
          :class="`uc-input-style w-icon-left ${required.areas}`"
          :create-option="(area) => ({ id: area, name: area })"
          :multiple="true"
          :options="areasList"
          :reduce="(area) => area.id"
          @input="
            setField({ id: 'areas', value: areas });
            clearRequired('areas');
          "
          id="areas"
          label="name"
          placeholder="Áreas de Interés"
          push-tags
          taggable
          v-model="areas"
          v-tooltip="`Para incluir más áreas, sólo ecríbelas y presiona enter`"
        >
          <span slot="no-options">Búsqueda no encontrada...</span>
        </v-select>
        <i class="uc-icon">gavel</i>
      </div>
    </div>

    <div class="col-md-12">
      <div class="row my-40">
        <div class="col-md-12">
          <div class="d-flex align-items-center">
            <h3>Experiencia Laboral</h3>
            <span class="uc-heading-decoration"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 mb-20">
      <div class="uc-form-group uc-radio-group">
        <input
          type="radio"
          id="experience_1"
          name="experience"
          value="1"
          class="uc-orig-radio"
          :checked="hasExperience == 1"
          v-model="hasExperience"
        />
        <label for="experience_1" class="uc-radio-button">Sí</label>
        <input
          type="radio"
          id="experience_2"
          name="experience"
          value
          class="uc-orig-radio"
          :checked="hasExperience"
          v-model="hasExperience"
        />
        <label for="experience_2" class="uc-radio-button">No</label>
      </div>
    </div>
    <Experience
      v-show="hasExperience"
      :experienceContent="firstExperienceContent"
    />
    <div class="col-md-6 mb-20" v-if="hasExperience">
      <div class="uc-form-group">
        <button
          style
          class="uc-btn btn-primary"
          @click="addElement(experiences, 'experience')"
        >
          Añadir Otra Experiencia
          <i class="uc-icon icon-shape--rounded ml-4">add</i>
        </button>
      </div>
    </div>
    <Experience
      v-for="(experience, key) in experiences"
      :key="key"
      :experienceKey="parseInt(key.match(/\d+/)[0])"
      :experienceContent="experience"
    />

    <div class="col-md-12">
      <div class="row my-40">
        <div class="col-md-12">
          <div class="d-flex align-items-center">
            <h3>Información Adicional</h3>
            <span class="uc-heading-decoration"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 mb-20">
      <div class="uc-form-group">
        <label for="english">¿Inglés?</label>
        <i class="uc-icon ml-4"></i>
      </div>
      <div class="uc-form-group uc-radio-group">
        <input
          type="radio"
          id="english_1"
          name="english"
          value="1"
          class="uc-orig-radio"
          v-model="hasEnglish"
          :checked="false"
        />
        <label for="english_1" class="uc-radio-button">Sí</label>
        <input
          type="radio"
          id="english_2"
          name="english"
          value
          class="uc-orig-radio"
          v-model="hasEnglish"
          :checked="true"
        />
        <label for="english_2" class="uc-radio-button">No</label>
      </div>
      <v-card-text v-if="hasEnglish">
        <v-subheader>Nivel Inglés</v-subheader>
        <v-slider
          :max="3"
          :tick-labels="['Usuario', 'Intermedio', 'Avanzado', 'Fluído']"
          @change="setField({ id: 'englishLevel', value: englishLevel })"
          step="1"
          tick-size="4"
          ticks="always"
          v-model="englishLevel"
        ></v-slider>
      </v-card-text>
    </div>
    <div class="col-md-12 mb-20">
      <div class="uc-form-group">
        <label for="anotherLanguage">¿Otro Idioma?</label>
        <i class="uc-icon ml-4"></i>
      </div>
      <div class="uc-form-group uc-radio-group">
        <input
          type="radio"
          id="anotherLanguage_1"
          name="anotherLanguageRadio"
          value="1"
          class="uc-orig-radio"
          v-model="hasAnotherLanguage"
          :checked="false"
        />
        <label for="anotherLanguage_1" class="uc-radio-button">Sí</label>
        <input
          type="radio"
          id="anotherLanguage_2"
          name="anotherLanguageRadio"
          value
          class="uc-orig-radio"
          v-model="hasAnotherLanguage"
          :checked="true"
        />
        <label for="anotherLanguage_2" class="uc-radio-button">No</label>
      </div>
      <div class="uc-form-group mt-20" v-if="hasAnotherLanguage">
        <label for="anotherLanguage">Indique Idioma</label>
        <input
          v-model="anotherLanguage"
          id="anotherLanguage"
          @change="setField({ id: 'anotherLanguage', value: anotherLanguage })"
          type="text"
          :class="`uc-input-style w-icon-left ${required.anotherLanguage}`"
          @keydown="clearRequired('anotherLanguage')"
          v-tooltip="requiredTooltip.anotherLanguage"
          placeholder="Idioma"
        />
        <i class="uc-icon">account_box</i>
      </div>
    </div>
    <div class="col-md-12 mb-20">
      <div class="uc-form-group">
        <label for="computing">¿Computación?</label>
        <i class="uc-icon ml-4"></i>
      </div>
      <div class="uc-form-group uc-radio-group">
        <input
          type="radio"
          id="computing_1"
          name="computingRadio"
          value="1"
          class="uc-orig-radio"
          v-model="hasComputing"
          :checked="false"
        />
        <label for="computing_1" class="uc-radio-button">Sí</label>
        <input
          type="radio"
          id="computing_2"
          name="computingRadio"
          value
          class="uc-orig-radio"
          v-model="hasComputing"
          :checked="true"
        />
        <label for="computing_2" class="uc-radio-button">No</label>
      </div>
      <div class="uc-form-group mt-20" v-if="hasComputing">
        <v-select
          :class="`uc-input-style w-icon-left ${required.computing}`"
          :create-option="(computing) => ({ id: computing, name: computing })"
          :multiple="true"
          :options="computingList"
          :reduce="(computing) => computing.id"
          @input="
            setField({ id: 'computing', value: computing });
            clearRequired('computing');
          "
          id="computing"
          label="name"
          placeholder="Seleccione..."
          push-tags
          taggable
          v-model="computing"
          v-tooltip="requiredTooltip.computing"
        >
          <span slot="no-options">Búsqueda no encontrada...</span>
        </v-select>
      </div>
    </div>
    <ChooseTextarea
      :model="socialWork"
      :show="showSocialWork"
      fieldName="socialWork"
      icon="accessibility"
      placeholder="los trabajos sociales que realices"
      store="CVForm"
      title="¿Realizas Trabajos Sociales?"
    />
    <ChooseTextarea
      :model="politicalActivities"
      :show="showPoliticalActivities"
      fieldName="politicalActivities"
      icon="mic"
      placeholder="las actividades políticas que realices"
      store="CVForm"
      title="¿Realizas Actividades Políticas?"
    />

    <div class="col-md-12 mb-20">
      <div class="uc-form-group">
        <label for="personalInterests">Intereses Personales</label>
        <textarea
          v-model="personalInterests"
          :class="`uc-input-style w-icon-left ${required}`"
          v-tooltip="requiredTooltip"
          rows="3"
          placeholder="Narra tus intereses personales"
          @change="
            setField({ id: 'personalInterests', value: personalInterests })
          "
          @keydown="clearRequired('personalInterests')"
        ></textarea>
        <i class="uc-icon">account_box</i>
      </div>
    </div>
  </div>
</template>

<script>
import Assistantship from "@/components/CV/Assistantship.vue";
import ChooseTextarea from "@/components/ChooseTextarea.vue";
import Cookies from "js-cookie";
import Diploma from "@/components/CV/Diploma.vue";
import Experience from "@/components/CV/Experience.vue";
import Master from "@/components/CV/Master.vue";
import Phd from "@/components/CV/Phd.vue";
import VTooltip from "v-tooltip";
import Vue from "vue";
import VueMask from "v-mask";
import moment from "moment";
import vSelect from "vue-select";
import { VDatePicker } from "vuetify/lib";
import { mapState, mapMutations } from "vuex";

Vue.use(VTooltip);
Vue.use(VueMask);

export default {
  components: {
    "v-select": vSelect,
    Assistantship,
    ChooseTextarea,
    Diploma,
    Experience,
    Master,
    Phd,
    VDatePicker,
  },
  props: {
    type: {
      default: 1,
      type: Number,
    },
    clear: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      mask: {
        rut: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, "-", /[\dkK]/],
      },
      birthMenu: false,
      anotherLanguage: "",
      areas: "",
      assistantships: {},
      firstAssistantshipsContent: [],
      birth: null,
      birthParsed: null,
      city: "",
      region: "",
      condition: "",
      computing: "",
      diplomas: {},
      firstDiplomaContent: [],
      email: "",
      experiences: {},
      firstExperienceContent: [],
      hasAnotherLanguage: "",
      hasAssistantship: "",
      hasComputing: "",
      hasDiploma: "",
      hasEnglish: "",
      hasExperience: "",
      hasMaster: "",
      hasPhd: "",
      englishLevel: null,
      masters: {},
      firstMasterContent: [],
      name: "",
      personalInterests: "",
      phds: {},
      firstPhdContent: [],
      phone: "",
      ranking: "",
      rut: "",
      score: "",
      socialWork: "",
      politicalActivities: "",
      showSocialWork: false,
      showPoliticalActivities: false,
      university: "",
      universitiesList: [],
      areasList: [],
      regionsList: [],
      conditionsList: [
        { id: 1, name: "Estudiante - Primer Año" },
        { id: 2, name: "Estudiante - Segundo Año" },
        { id: 3, name: "Estudiante - Tercer Año" },
        { id: 4, name: "Estudiante - Cuarto Año" },
        { id: 5, name: "Estudiante - Quinto Año" },
        { id: 6, name: "Licenciado en Ciencias Jurídicas" },
        { id: 7, name: "Egresado" },
        { id: 8, name: "Abogado" },
      ],
      computingList: [
        { id: 1, name: "Uso de Internet" },
        { id: 2, name: "Manejo de Aplicaciones Windows (Word, Excel)" },
      ],
    };
  },
  computed: mapState("CVForm", ["required", "requiredTooltip"]),
  beforeMount() {
    moment.locale("es");
    this.completeFields();
    this.universitiesList = this.$store.state.CVForm["universitiesList"];
    this.areasList = this.$store.state.CVForm["areasList"];
    this.regionsList = this.$store.state.CVForm["regionsList"];
  },
  watch: {
    birthMenu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    birth() {
      this.birthParsed = this.birth
        ? moment(this.birth).format("DD/MM/Y")
        : null;
    },
    experienceDate() {
      switch (this.experienceDate.length) {
        case 1:
          this.experienceDatePlaceholder = `Desde ${moment(
            this.experienceDate[0] + "-01"
          ).format("MMM/Y")} - Actualidad`;
          break;
        case 2:
          this.experienceDatePlaceholder = `Desde ${moment(
            this.experienceDate[0] + "-01"
          ).format("MMM/Y")} - Hasta ${moment(
            this.experienceDate[1] + "-01"
          ).format("MMM/Y")}`;
          break;
        default:
          this.experienceDatePlaceholder = null;
      }
    },
  },
  methods: {
    ...mapMutations("CVForm", ["setField"]),
    addElement(element, name) {
      Vue.set(element, `${name}_${Object.entries(element).length + 1}`, []);
    },
    completeFields() {
      const fields = Cookies.getJSON("fields");
      if (typeof fields == "undefined") {
        return;
      }

      for (var field in fields) {
        this.$data[field] = fields[field];
      }
      if (fields["assistantships"]) {
        this.hasAssistantship = 1;
        const assistantships = fields["assistantships"];
        this.firstAssistantshipsContent = assistantships["assistantship_0"];
        delete assistantships["assistantship_0"];
        this.assistantships = assistantships;
      }
      if (fields["experiences"]) {
        this.hasExperience = 1;
        const experiences = fields["experiences"];
        this.firstExperienceContent = experiences["experience_0"];
        delete experiences["experience_0"];
        this.experiences = experiences;
      }
      if (fields["diplomas"]) {
        this.hasDiploma = 1;
        const diplomas = fields["diplomas"];
        this.firstDiplomaContent = diplomas["diploma_0"];
        delete diplomas["diploma_0"];
        this.diplomas = diplomas;
      }
      if (fields["masters"]) {
        this.hasMaster = 1;
        const masters = fields["masters"];
        this.firstMasterContent = masters["master_0"];
        delete masters["master_0"];
        this.masters = masters;
      }
      if (fields["phds"]) {
        this.hasPhd = 1;
        const phds = fields["phds"];
        this.firstPhdContent = phds["phd_0"];
        delete phds["phd_0"];
        this.phds = phds;
      }
      if (fields["englishLevel"]) {
        this.hasEnglish = 1;
      }
      if (fields["anotherLanguage"]) {
        this.hasAnotherLanguage = 1;
      }
      if (fields["computing"]) {
        this.hasComputing = 1;
      }
      if (fields["socialWork"]) {
        this.showSocialWork = true;
      }
      if (fields["politicalActivities"]) {
        this.showPoliticalActivities = true;
      }
    },
    clearRequired(el) {
      if (this.$store.state.CVForm.required[el]) {
        this.$store.commit("CVForm/clearRequired", el);
      }
    },
    validateRut(value) {
      const required = { ...this.$store.state.CVForm.required };
      const requiredTooltip = {
        ...this.$store.state.CVForm.requiredTooltip,
      };

      if (value.length < 10) {
        required.rut = "uc-alert error";
        requiredTooltip.rut = "RUT no válido";
        this.$store.commit("CVForm/required", { required, requiredTooltip });

        return;
      }

      const text = value.split("-");
      let rut = text[0];
      let m = 0;
      let dv = 1;

      for (; rut; rut = Math.floor(rut / 10)) {
        dv = (dv + (rut % 10) * (9 - (m++ % 6))) % 11;
      }

      if ((dv ? dv - 1 : "k") != text[1].toLowerCase()) {
        required.rut = "uc-alert error";
        requiredTooltip.rut = "RUT no válido";
      }

      this.$store.commit("CVForm/required", { required, requiredTooltip });
    },
  },
};
</script>

<style lang="sass">
@import "vue-select/src/scss/vue-select.scss"
.vs__dropdown-toggle
  border: 0
  margin: -5px
  padding: 0

.vs__open-indicator
  margin-top: 3px

.uc-modal
  padding-top: 50px !important

.uc-modal .uc-modal_content
  width: 60%

.uc-input-style.w-icon-left
  background-position: left 16px center !important
  padding: 12px 40px

textarea.uc-input-style.w-icon-left
  padding: 30px 40px

select[multiple="multiple"]
  height: auto

select[multiple="multiple"] + .uc-icon
  top: 70px

.w-icon-left + .uc-icon
  left: 10px
  position: absolute
  top: 42px
</style>
