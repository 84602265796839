<template>
  <div class="law-stand-box" @click="openModal">
    <div class="law-stand-box_body">
      <div class="law-stand-box_square">
        <div :style="setImage()" class="law-stand-box-img"></div>
        <strong style="color: #fff">Ingresa Aquí</strong>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    setImage() {
      return `
        background-image: url('${process.env.VUE_APP_REDES_BASE_PATH}/uploads/feria/logos/llm_uc_logo.png');
      `;
    },
    openModal() {
      this.$parent.showLLMModal = true;
      this.$parent.$parent.$children[0].$children[0].$refs.live.player.mute();
    },
  },
};
</script>

<style lang="sass">
.law-stand-box
  cursor: pointer
  display: inline-block
  height: 180px
  transition: transform .2s
  z-index: 1

.law-stand-box:hover
  transform: scale(1.3)
  z-index: 50

.law-stand-box_square
  box-shadow: rgb(0 0 0 / 60%) 0 0 15px
  padding: 5px 0
  position: relative
  top: -100px
  width: 230px
  height: 100px

.law-stand-box-img
  background-position: center
  background-repeat: no-repeat
  background-size: contain
  height: 100%
  margin-bottom: 5px

.law-stand-box_body
  background-color: #4472b4
  height: 30px
  position: relative
  top: 90px
</style>
