var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"uc-form-group"},[_c('label',{attrs:{"for":("phdUniversity_" + _vm.phdKey)}},[_vm._v("Universidad")]),_c('v-select',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
        "Si tu universidad no está en el listado, sólo ecríbela y presiona enter"
      ),expression:"\n        `Si tu universidad no está en el listado, sólo ecríbela y presiona enter`\n      "}],class:"uc-input-style w-icon-left",attrs:{"create-option":function (phdUniversity) { return ({ id: phdUniversity, name: phdUniversity }); },"options":_vm.universitiesList,"reduce":function (university) { return university.id; },"id":("phdUniversity_" + _vm.phdKey),"label":"name","placeholder":"Universidad","push-tags":"","taggable":""},on:{"input":function($event){return _vm.setFieldArray({
          field: "phds",
          id: ("phd_" + _vm.phdKey),
          value: {
            id: "university",
            value: _vm.phdUniversity,
          },
        })}},model:{value:(_vm.phdUniversity),callback:function ($$v) {_vm.phdUniversity=$$v},expression:"phdUniversity"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Búsqueda no encontrada...")])]),_c('i',{staticClass:"uc-icon"},[_vm._v("school")])],1),_c('div',{staticClass:"uc-form-group"},[_c('label',{attrs:{"for":("phdName_" + _vm.phdKey)}},[_vm._v("Nombre")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phdName),expression:"phdName"}],class:"uc-input-style w-icon-left",attrs:{"id":("phdName_" + _vm.phdKey),"type":"text","placeholder":"Nombre"},domProps:{"value":(_vm.phdName)},on:{"change":function($event){return _vm.setFieldArray({
          field: "phds",
          id: ("phd_" + _vm.phdKey),
          value: {
            id: "name",
            value: _vm.phdName,
          },
        })},"input":function($event){if($event.target.composing){ return; }_vm.phdName=$event.target.value}}}),_c('i',{staticClass:"uc-icon"},[_vm._v("account_box")])]),_c('div',{staticClass:"uc-form-group"},[_c('label',{attrs:{"for":("phdYear_" + _vm.phdKey)}},[_vm._v("Año Término")]),_c('v-select',{class:"uc-input-style w-icon-left",attrs:{"options":_vm.years,"reduce":function (year) { return year.id; },"id":("phdYear_" + _vm.phdKey),"label":"year","placeholder":"Seleccione..."},on:{"input":function($event){return _vm.setFieldArray({
          field: "phds",
          id: ("phd_" + _vm.phdKey),
          value: {
            id: "year",
            value: _vm.phdYear,
          },
        })}},model:{value:(_vm.phdYear),callback:function ($$v) {_vm.phdYear=$$v},expression:"phdYear"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Búsqueda no encontrada...")])]),_c('i',{staticClass:"uc-icon"},[_vm._v("school")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }