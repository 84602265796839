<template>
  <div class="uc-modal law-modal" style="display: block" @click="close">
    <div class="uc-modal_content uc-message mb-32" @click="keepOpen">
      <div class="modal-header" style="background-color: #4472b4">
        <div class="row">
          <div class="col-md-2">
            <div class="stand-modal_logo m-20">
              <div class="stand-modal_square">
                <div class="law-modal_img" :style="setImage()"></div>
              </div>
            </div>
          </div>
          <div
            class="col-10 offset-1 col-xl-8 offset-xl-0 col-md-8 offset-md-1 offset-sm-1 d-flex align-items-center"
          >
            <h2 style="color: #fff">Educación Contínua</h2>
          </div>
        </div>
      </div>
      <div class="uc-message_body mt-20">
        <v-tabs :vertical="vertical" show-arrows>
          <v-tab>Video</v-tab>
          <v-tab>Fotos</v-tab>
          <v-tab>Conoce nuestros diplomados</v-tab>
          <v-tab>Contacto</v-tab>
          <v-tab-item style="height: 100%; text-align: -webkit-center">
            <v-card
              max-width="720"
              elevation="10"
              style="height: 100%; box-shadow: none"
            >
              <youtube
                video-id="IrLKMNTGCTk"
                allowfullscreen
                frameborder="0"
                player-width="100%"
                ref="video"
                class="youtube-video"
                style="height: 100%; padding: 5px"
              ></youtube>
            </v-card>
          </v-tab-item>
          <v-tab-item style="text-align: -webkit-center">
            <div>
              <carousel
                :autoplay="true"
                :autoplayTimeout="2000"
                :loop="true"
                :perPage="1"
              >
                <slide
                  v-for="(photo, key) in [
                    'uploads/documentos_compartidos/Educacion_continua_1920x1080.jpg',
                  ]"
                  :key="key"
                >
                  <div :style="getImgUrl(`${baseUrl}/${photo}`)"></div>
                </slide>
              </carousel>
            </div>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <div class="uc-table-list">
                  <ul class="uc-table-list_content" style="border-top: 0">
                    <li>
                      <a
                        href="https://educacioncontinua.uc.cl/programas/diplomado-en-analisis-y-planificacion-tributaria"
                        target="_blank"
                        class="uc-btn btn-listed"
                      >
                        Diplomado en Análisis y Planificación Tributaria
                        <i class="uc-icon icon-small">launch</i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://educacioncontinua.uc.cl/programas/diplomado-en-compliance-y-buenas-practicas-corporativas"
                        class="uc-btn btn-listed"
                      >
                        Diplomado en Compliance y Buenas Prácticas Corporativas
                        <i class="uc-icon icon-small">launch</i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://educacioncontinua.uc.cl/programas/diplomado-en-contratacion-administrativa-y-compras-publicas"
                        class="uc-btn btn-listed"
                      >
                        Diplomado en Contratación Administrativa y Compras
                        Públicas
                        <i class="uc-icon icon-small">launch</i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://educacioncontinua.uc.cl/programas/diplomado-en-criminologia-"
                        class="uc-btn btn-listed"
                      >
                        Diplomado en Criminología, Política Criminal y Derechos
                        Humanos
                        <i class="uc-icon icon-small">launch</i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://educacioncontinua.uc.cl/programas/diplomado-en-derecho-a-la-proteccion-de-la-salud-y-gestion-sanitaria"
                        class="uc-btn btn-listed"
                      >
                        Diplomado en Derecho a la Protección de la Salud y
                        Gestión Sanitaria
                        <i class="uc-icon icon-small">launch</i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://educacioncontinua.uc.cl/programas/diplomado-en-derecho-administrativo-mencion-en-derecho-administrativo-general/"
                        class="uc-btn btn-listed"
                      >
                        Diplomado en Derecho administrativo con mención en
                        derecho administrativo general
                        <i class="uc-icon icon-small">launch</i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://educacioncontinua.uc.cl/programas/diplomado-en-derecho-administrativo-mencion-en-derecho-de-bienes-publicos/"
                        class="uc-btn btn-listed"
                      >
                        Diplomado en Derecho administrativo con mención en
                        derecho de bienes públicos
                        <i class="uc-icon icon-small">launch</i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://educacioncontinua.uc.cl/programas/diplomado-en-derecho-administrativo-sancionador/"
                        class="uc-btn btn-listed"
                      >
                        Diplomado en Derecho administrativo sancionador
                        <i class="uc-icon icon-small">launch</i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://educacioncontinua.uc.cl/programas/diplomado-en-derecho-concursal"
                        class="uc-btn btn-listed"
                      >
                        Diplomado en Derecho Concursal
                        <i class="uc-icon icon-small">launch</i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://educacioncontinua.uc.cl/programas/diplomado-en-derecho-de-la-construccion--"
                        class="uc-btn btn-listed"
                      >
                        Diplomado en Derecho de la Construcción
                        <i class="uc-icon icon-small">launch</i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://educacioncontinua.uc.cl/programas/diplomado-en-derecho-de-la-seguridad-social/"
                        class="uc-btn btn-listed"
                      >
                        Derecho de la Seguridad Social
                        <i class="uc-icon icon-small">launch</i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://educacioncontinua.uc.cl/programas/diplomado-en-derecho-de-recursos-naturales-con-mencion-en-derecho-de-aguas/"
                        class="uc-btn btn-listed"
                      >
                        Derecho de recursos naturales con mención en derecho de
                        aguas
                        <i class="uc-icon icon-small">launch</i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://educacioncontinua.uc.cl/programas/diplomado-en-derecho-de-recursos-naturales-con-mencion-en-derecho-de-energia/"
                        class="uc-btn btn-listed"
                      >
                        Derecho de recursos naturales con mención en derecho de
                        energía
                        <i class="uc-icon icon-small">launch</i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://educacioncontinua.uc.cl/programas/diplomado-en-derecho-de-recursos-naturales-con-mencion-en-derecho-de-mineria/"
                        class="uc-btn btn-listed"
                      >
                        Derecho de recursos naturales con mención en derecho de
                        minería
                        <i class="uc-icon icon-small">launch</i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://educacioncontinua.uc.cl/programas/diplomado-en-derecho-de-seguros--"
                        class="uc-btn btn-listed"
                      >
                        Diplomado en Derecho de Seguros
                        <i class="uc-icon icon-small">launch</i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://educacioncontinua.uc.cl/programas/diplomado-en-derecho-del-trabajo-mencion-derecho-colectivo-y-negociacion/"
                        class="uc-btn btn-listed"
                      >
                        Diplomado en Derecho del trabajo, mención derecho
                        colectivo y negociación
                        <i class="uc-icon icon-small">launch</i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://educacioncontinua.uc.cl/programas/diplomado-en-derecho-del-trabajo-mencion-en-procedimiento-y-litigacion-laboral/"
                        class="uc-btn btn-listed"
                      >
                        Diplomado en Derecho del trabajo, mención en
                        procedimiento y litigación laboral
                        <i class="uc-icon icon-small">launch</i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://educacioncontinua.uc.cl/programas/diplomado-en-derecho-e-inteligencia-artificial"
                        class="uc-btn btn-listed"
                      >
                        Diplomado en Derecho e Inteligencia Artificial
                        <i class="uc-icon icon-small">launch</i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://educacioncontinua.uc.cl/programas/diplomado-en-derecho-municipal-fundamentos-y-aspectos-practicos/"
                        class="uc-btn btn-listed"
                      >
                        Derecho Municipal: fundamentos y aspectos prácticos
                        <i class="uc-icon icon-small">launch</i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://educacioncontinua.uc.cl/programas/diplomado-en-derecho-penal-economico/"
                        class="uc-btn btn-listed"
                      >
                        Diplomado en Derecho penal económico
                        <i class="uc-icon icon-small">launch</i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://educacioncontinua.uc.cl/programas/diplomado-en-derecho-procesal-avanzado-y-litigacion-mencion-en-procedimiento-civil/"
                        class="uc-btn btn-listed"
                      >
                        Diplomado en Derecho procesal avanzado y litigación
                        mención en procedimiento civil
                        <i class="uc-icon icon-small">launch</i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://educacioncontinua.uc.cl/programas/diplomado-en-derecho-procesal-avanzado-y-litigacion-mencion-en-procedimiento-de-familia/"
                        class="uc-btn btn-listed"
                      >
                        Diplomado en Derecho procesal avanzado y litigación
                        mención en procedimiento de familia
                        <i class="uc-icon icon-small">launch</i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://educacioncontinua.uc.cl/programas/diplomado-en-derecho-procesal-avanzado-y-litigacion-mencion-en-procedimiento-laboral/"
                        class="uc-btn btn-listed"
                      >
                        Diplomado en derecho procesal avanzado y litigación
                        mención en procedimiento laboral
                        <i class="uc-icon icon-small">launch</i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://educacioncontinua.uc.cl/programas/diplomado-en-derecho-procesal-avanzado-y-litigacion-mencion-en-procedimiento-penal/"
                        class="uc-btn btn-listed"
                      >
                        Diplomado en derecho procesal avanzado y litigación
                        mención en procedimiento penal
                        <i class="uc-icon icon-small">launch</i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://educacioncontinua.uc.cl/programas/diplomado-en-derecho-y-negocio-inmobiliario"
                        class="uc-btn btn-listed"
                      >
                        Diplomado en Derecho y Negocio Inmobiliario
                        <i class="uc-icon icon-small">launch</i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://educacioncontinua.uc.cl/programas/diplomado-de-especializacion-en-juicios-orales-y-tecnicas-de-litigacion/"
                        class="uc-btn btn-listed"
                      >
                        Diplomado de Especialización en juicios orales y
                        técnicas de litigación
                        <i class="uc-icon icon-small">launch</i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://educacioncontinua.uc.cl/programas/diplomado-en-gestion-y-regulacion-del-agua--"
                        class="uc-btn btn-listed"
                      >
                        Diplomado en Gestión y Regulación del Agua
                        <i class="uc-icon icon-small">launch</i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://educacioncontinua.uc.cl/programas/diplomado-en-libre-competencia"
                        class="uc-btn btn-listed"
                      >
                        Diplomado en Libre Competencia
                        <i class="uc-icon icon-small">launch</i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://educacioncontinua.uc.cl/programas/diplomado-los-derechos-de-los-ninos-ninas-y-adolescentes-victimas-de-delitos-sexuales-y-el-sistema-judicial"
                        class="uc-btn btn-listed"
                      >
                        Diplomado en Los derechos de los niños, niñas y
                        adolescentes, víctimas de delitos sexuales y el sistema
                        judicial
                        <i class="uc-icon icon-small">launch</i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://educacioncontinua.uc.cl/programas/diplomado-en-medioambiente-y-desarrollo-sustentable/"
                        class="uc-btn btn-listed"
                      >
                        Diplomado en Medioambiente y desarrollo sustentable
                        <i class="uc-icon icon-small">launch</i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://educacioncontinua.uc.cl/programas/diplomado-en-negociacion-y-liderazgo-para-la-gestion-de-conflictos/"
                        class="uc-btn btn-listed"
                      >
                        Diplomado en Negociación y liderazgo para la gestión de
                        conflictos
                        <i class="uc-icon icon-small">launch</i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://educacioncontinua.uc.cl/programas/diplomado-en-probidad-transparencia-y-buen-gobierno-"
                        class="uc-btn btn-listed"
                      >
                        Diplomado en Probidad, Transparencia y Buen Gobierno
                        <i class="uc-icon icon-small">launch</i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://educacioncontinua.uc.cl/programas/diplomado-de-propiedad-intelectual/"
                        class="uc-btn btn-listed"
                      >
                        Diplomado en Propiedad intelectual
                        <i class="uc-icon icon-small">launch</i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://educacioncontinua.uc.cl/programas/diplomado-en-proteccion-de-datos-personales-marco-regulatorio-y-aplicacion-practica/"
                        class="uc-btn btn-listed"
                      >
                        Diplomado en Protección de datos personales. Marco
                        regulatorio y aplicación práctica
                        <i class="uc-icon icon-small">launch</i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://educacioncontinua.uc.cl/programas/diplomado-en-regulacion-y-contratacion-fintech/"
                        class="uc-btn btn-listed"
                      >
                        Diplomado en Regulación y contratación Fintech
                        <i class="uc-icon icon-small">launch</i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://educacioncontinua.uc.cl/programas/diplomado-en-sistema-procesal-penal"
                        class="uc-btn btn-listed"
                      >
                        Diplomado en Sistema Procesal Penal
                        <i class="uc-icon icon-small">launch</i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://educacioncontinua.uc.cl/programas/diplomado-en-venture-capital-regulacion-y-estrategias-de-inversion/"
                        class="uc-btn btn-listed"
                      >
                        Diplomado en Venture Capital: regulación y estrategias
                        de inversión
                        <i class="uc-icon icon-small">launch</i>
                      </a>
                    </li>
                  </ul>
                </div>
                <p>
                  Revisa toda la información de Educación Continua Derecho UC
                  <a
                    href="https://educacioncontinua.uc.cl/programas/?area-tematica=derecho"
                    >aquí<i class="uc-icon icon-small">launch</i></a
                  >
                  o escaneando el QR
                </p>
                <p style="text-align: center">
                  <qrcode-vue
                    value="https://educacioncontinua.uc.cl/programas/?area-tematica=derecho"
                    size="220"
                    margin="1"
                    level="L"
                  />
                </p>
                <div class="uc-table-list">
                  <ul class="uc-table-list_content" style="border-top: 0">
                    <li>
                      <a
                        href="https://educacioncontinua.uc.cl/programas/?area-tematica=derecho"
                        target="_blank"
                        class="uc-btn btn-listed"
                      >
                        Página Educación Continua
                        <i class="uc-icon icon-small">launch</i>
                      </a>
                    </li>
                  </ul>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text class="text-center">
                <p class="text-center">
                  Deja tus datos en este QR, o haz clic
                  <a
                    href="https://extensionderecho.uc.cl/diplomados-derecho-2024/?utm_source=feria&amp;utm_medium=qr&amp;utm_campaign=diplomados2024"
                    >aquí<i class="uc-icon">launch</i>
                  </a>
                </p>
                <qrcode-vue
                  value="https://extensionderecho.uc.cl/diplomados-derecho-2024/?utm_source=feria&amp;utm_medium=qr&amp;utm_campaign=diplomados2024"
                  :size="this.imageHeight * 0.6"
                  margin="1"
                  level="L"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
        <div class="rrss">
          <div class="rrss-column">
            <a
              :href="media"
              :key="key"
              style="text-decoration: none"
              target="_blank"
              v-for="(media, key) in {
                web: 'https://extensionderecho.uc.cl/',
                twitter: 'https://twitter.com/derechouc',
                facebook: 'https://www.facebook.com/derechouc',
                youtube: 'https://www.youtube.com/DerechoUCOficial',
                linkedin:
                  'https://www.linkedin.com/school/11451108/admin/feed/posts/',
              }"
              v-show="media"
            >
              <v-icon right> mdi-{{ key }} </v-icon>
            </a>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <hr class="uc-hr my-32" />
        <div class="row align-items-center">
          <div class="col-md-10 text-right"></div>
          <div class="col-md-2 text-right">
            <a
              href="javascript:void(0)"
              class="uc-link uc-btn btn-inline"
              @click="close"
            >
              Cerrar
              <i class="uc-icon icon-shape--rounded">close</i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import QrcodeVue from "qrcode.vue";

export default {
  components: { Carousel, Slide, QrcodeVue },
  data() {
    return {
      baseUrl: process.env.VUE_APP_REDES_BASE_PATH,
      vertical: window.innerWidth >= 1000 ? true : false,
      windowWidth: window.innerWidth,
      imageHeight: 0,
    };
  },
  methods: {
    getImgUrl(url) {
      return `
        background-image: url('${url}');
        background-position: center;
        background-size: contain;
        height: ${this.imageHeight}px;
        width: 100%;
      `;
    },
    close() {
      this.$parent.$parent.$children[0].$children[0].$refs.live.player.unMute();
      this.$parent.showCEModal = false;
    },
    setImage() {
      return `
        background-image: url('${process.env.VUE_APP_REDES_BASE_PATH}/uploads/feria/logos/FacDerecho.jpg');
      `;
    },
    closeModal(event) {
      if (event.keyCode === 27) {
        if (this.$parent.showModal) {
          this.$parent.showModal = false;
          return;
        }
        this.close();
      }
    },
    keepOpen(event) {
      event.stopPropagation();
    },
    onResize() {
      this.windowWidth = window.innerWidth;
      this.imageHeight =
        window.document.getElementsByClassName(
          "uc-message_body"
        )[0].childNodes[0].clientHeight;
    },
  },
  mounted() {
    document
      .querySelector(".modal-footer .row .col-md-10")
      .appendChild(document.querySelector("[role='tablist']"));
    document.addEventListener("keyup", this.closeModal);
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.imageHeight =
      window.document.getElementsByClassName(
        "uc-message_body"
      )[0].childNodes[0].clientHeight;
  },
  destroyed() {
    document.removeEventListener("keyup", this.closeModal);
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    windowWidth(newWidth) {
      if (newWidth < 1000) {
        this.vertical = false;
      }
      if (newWidth >= 1000) {
        this.vertical = true;
      }
    },
  },
};
</script>

<style lang="sass">
.uc-modal.law-modal
  .uc-modal_content
    width: 95% !important
  .v-tab--active
    background-color: #4472b4
    color: #fff !important
    .uc-icon.mdi
      color: #fff !important
  .v-tabs
    height: 100%
  .v-tabs-items
    overflow-y: auto
    max-height: 100%
  .v-tabs.v-tabs--vertical
    padding-bottom: 0
    .v-tabs-bar
      width: 450px
    .v-tabs-bar__content
      padding: 10px
  .v-tabs-items
    .v-window__container
      height: 95%
  .v-tabs--vertical
    .v-tab
      font-weight: bold
      justify-content: left !important
      max-width: 100% !important
  .v-tabs--vertical
    .v-tab--active
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75)
      height: 80px !important
  .v-tabs-slider-wrapper
    width: 0px !important
  .v-card__text
    margin: 0

.uc-table-list
  a
    color: rgba(0, 0, 0, 0.54) !important
  a:focus
    outline: 0px solid rgba(1,118,222,.7)

.uc-modal .uc-modal_content
  width: 90% !important

.modal-header
  border-top-left-radius: 4px
  border-top-right-radius: 4px
  height: auto
  padding-bottom: 20px

@media (min-width: 1150px)
  .modal-header
    height: 150px
    padding-bottom: 0

@media (min-width: 1220px)
  .modal-header
    height: 130px
    padding-bottom: 0

.law-modal_logo
  display: inline-block

.law-modal_circle
  background-color: #fff
  border-radius: 50%
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 15px
  margin: auto
  position: relative
  height: 160px
  width: 160px
.uc-modal.law-modal
  .v-tab--active
    background-color: #4472b4
    color: #fff !important
    .uc-icon.mdi
      color: #fff !important
  .uc-modal_content
    height: 100%
    min-height: 600px
    width: 95% !important
  .modal-header
    background-color: var(--bg-color)
    border-top-left-radius: 4px
    border-top-right-radius: 4px
    height: auto
    padding-bottom: 20px
  .uc-message_body
    height: 100%
    padding-bottom: 148px !important
    padding-left: 48px !important
    padding-right: 48px !important
    padding-top: 250px !important
    position: absolute
    top: 10px
    width: 100%
    .v-tabs
      display: inline-grid
      width: 95%
    .rrss
      display: inline-grid
      position: absolute
      width: 5%
      .rrss-column
        display: grid
        justify-content: center
        a
          padding-bottom: 10px
  .modal-footer
    background-color: #fff
    bottom: 20px
    padding: 0 48px
    position: absolute
    width: 100%
  .youtube-video > iframe
    height: 100%
    width: 100%

.law-modal_img
  background-position: center
  background-repeat: no-repeat
  background-size: contain
  height: 100%
  margin: auto
  width: 90%

#extension_hall
  background-image: url(http://intranetredes.derecho.uc.cl/uploads/documentos_compartidos/centro_de_extension.JPG)
  background-size: cover
  height: 100%
  opacity: 0.25
  margin: -10px
  position: fixed
  width: 100%
</style>
