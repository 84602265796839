<template>
  <div class="uc-modal stand-modal" :style="cssVars" @click="close">
    <div class="uc-modal_content uc-message mb-32" @click="keepOpen">
      <div class="modal-header">
        <div class="row">
          <div class="col-md-2">
            <div class="stand-modal_logo m-20">
              <div class="stand-modal_square">
                <div class="stand-modal_img" :style="setImage()"></div>
              </div>
            </div>
          </div>
          <div
            class="col-10 offset-1 col-xl-8 offset-xl-0 col-md-8 offset-md-1 offset-sm-1 d-flex align-items-center"
          >
            <h2 style="color: #fff">{{ stand.name }}</h2>
          </div>
        </div>
      </div>
      <div class="uc-message_body mt-20">
        <v-tabs :vertical="vertical" show-arrows>
          <v-tab v-if="stand.photos.length" v-on:click="stopVideo">
            <div :class="{ row: vertical }">
              <div :class="{ 'col-md-10 text-left': vertical }">
                <v-icon left> mdi-image-multiple </v-icon>
                Imágenes
              </div>
              <div v-if="vertical" class="col-md-2 text-right">
                <v-icon right> mdi-arrow-right </v-icon>
              </div>
            </div>
          </v-tab>
          <v-tab v-on:click="stopVideo">
            <div :class="{ row: vertical }">
              <div :class="{ 'col-md-10 text-left': vertical }">
                <v-icon left> mdi-information </v-icon>
                Nosotros
              </div>
              <div v-if="vertical" class="col-md-2 text-right">
                <v-icon right> mdi-arrow-right </v-icon>
              </div>
            </div>
          </v-tab>
          <v-tab v-if="stand.internship" v-on:click="stopVideo">
            <div :class="{ row: vertical }">
              <div :class="{ 'col-md-10 text-left': vertical }">
                <v-icon left> mdi-wallet-travel </v-icon>
                Pasantías
              </div>
              <div v-if="vertical" class="col-md-2 text-right">
                <v-icon right> mdi-arrow-right </v-icon>
              </div>
            </div>
          </v-tab>
          <v-tab v-if="stand.video" v-on:click="stopVideo">
            <div :class="{ row: vertical }">
              <div :class="{ 'col-md-10 text-left': vertical }">
                <v-icon left> mdi-video </v-icon>
                Video
              </div>
              <div v-if="vertical" class="col-md-2 text-right">
                <v-icon right> mdi-arrow-right </v-icon>
              </div>
            </div>
          </v-tab>
          <v-tab v-if="stand.talk" v-on:click="stopVideo">
            <div :class="{ row: vertical }">
              <div :class="{ 'col-md-10 text-left': vertical }">
                <v-icon left> mdi-video-account </v-icon>
                Charla
              </div>
              <div v-if="vertical" class="col-md-2 text-right">
                <v-icon right> mdi-arrow-right </v-icon>
              </div>
            </div>
          </v-tab>
          <v-tab
            v-if="![482].includes(parseInt(stand.id))"
            v-on:click="stopVideo"
          >
            <div :class="{ row: vertical }">
              <div :class="{ 'col-md-10 text-left': vertical }">
                <v-icon left> mdi-file-document-edit </v-icon>
                Completa tu CV
              </div>
              <div v-if="vertical" class="col-md-2 text-right">
                <v-icon right> mdi-arrow-right </v-icon>
              </div>
            </div>
          </v-tab>
          <v-tab v-if="allowQuestion(stand.id)" v-on:click="stopVideo">
            <div :class="{ row: vertical }">
              <div :class="{ 'col-md-10 text-left': vertical }">
                <i class="uc-icon mdi">question_answer</i>
                Preguntas y Respuestas
              </div>
              <div v-if="vertical" class="col-md-2 text-right">
                <v-icon right> mdi-arrow-right </v-icon>
              </div>
            </div>
          </v-tab>
          <v-tab-item
            v-if="stand.photos.length"
            style="text-align: -webkit-center"
          >
            <div>
              <carousel
                :autoplay="true"
                :autoplayTimeout="2000"
                :loop="true"
                :perPage="1"
              >
                <slide v-for="(photo, key) in stand.photos" :key="key">
                  <div :style="getImgUrl(`${baseUrl}/${photo}`)"></div>
                </slide>
              </carousel>
            </div>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <div v-html="stand.description"></div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="stand.internship">
            <v-card flat>
              <v-card-text>
                <div v-html="stand.internship"></div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item
            v-if="stand.video"
            style="height: 100%; text-align: -webkit-center"
          >
            <v-card
              max-width="720"
              elevation="10"
              style="height: 100%; box-shadow: none"
            >
              <youtube
                :video-id="stand.video.match(/embed\/(.*)\?/)[1]"
                allowfullscreen
                frameborder="0"
                player-width="100%"
                ref="video"
                class="youtube-video"
                style="height: 100%; padding: 5px"
              ></youtube>
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="stand.talk" style="text-align: -webkit-center">
            <v-card max-width="720" elevation="10">
              <youtube
                :video-id="stand.talk.match(/embed\/(.*)\?/)[1]"
                allowfullscreen
                frameborder="0"
                player-width="100%"
                ref="talk"
                style="padding: 5px"
              ></youtube>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card class="m-12 p-12" style="top: -5px">
              <template v-if="stand.id == 280">
                Instrucción para ingresar antecedentes curriculares.<br /><br />Si
                desea ingresar sus antecedentes curriculares en el portal web de
                Contraloría, haga click en el icono "ciudadano" y luego en
                "trabaje con nosotros", donde debe registrarse o actualizar sus
                antecedentes en el caso que se haya registrado anteriormente.
                <br /><br />Cabe señalar que cuando la Institución requiera
                incorporar personal, dichos antecedentes serán considerados de
                acuerdo con las necesidades del Organismo, las disponibilidades
                presupuestarias y en la medida que cumplan con los perfiles de
                los cargos a proveer.
              </template>
              <template v-else>
                <CVForm />
                <div class="col-md-12 text-right">
                  <a
                    href="javascript:void(0)"
                    class="uc-link uc-btn btn-inline ml-16"
                    @click="send"
                  >
                    {{ sendText }}
                    <i class="uc-icon icon-shape--rounded">send</i>
                  </a>
                </div>
              </template>
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="allowQuestion(stand.id)">
            <v-card class="m-12 p-12" style="height: 100%; top: -5px">
              <div class="container">
                <div class="row">
                  <div class="col-md-12 text-right">
                    <a
                      href="javascript:void(0)"
                      class="uc-link uc-btn btn-inline ml-16"
                      v-on:click="handleQuestionForm"
                    >
                      {{ askQuestionText }}
                      <i class="uc-icon">help_outline</i>
                    </a>
                  </div>
                </div>
                <transition name="slide-fade">
                  <v-app v-if="!questionForm" class="row">
                    <v-timeline
                      v-if="qas.length"
                      :dense="$vuetify.breakpoint.smAndDown"
                    >
                      <template v-for="(qa, key) in qas">
                        <v-timeline-item
                          :key="`${key}-question`"
                          color="#FC9728"
                          fill-dot
                          left
                        >
                          <v-card>
                            <v-card-title style="background-color: #fc9728">
                              <h4 class="white--text font-weight-light">
                                Pregunta de {{ qa.name }}
                              </h4>
                            </v-card-title>
                            <v-container>
                              <v-row>
                                <v-col cols="12" md="10">
                                  {{ qa.question }}
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card>
                        </v-timeline-item>
                        <v-timeline-item
                          :key="`${key}-answer`"
                          :color="`${stand.color}`"
                          fill-dot
                          right
                        >
                          <v-card>
                            <v-card-title class="stand-bg">
                              <h4 class="white--text font-weight-light">
                                Respuesta de {{ stand.name }}
                              </h4>
                            </v-card-title>
                            <v-container>
                              <v-row>
                                <v-col>
                                  {{ qa.answer }}
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card>
                        </v-timeline-item>
                      </template>
                    </v-timeline>
                  </v-app>
                </transition>
                <transition name="slide-fade">
                  <div v-if="questionForm" class="row" id="question-form">
                    <div class="col-md-12">
                      <div class="uc-form-group">
                        <label for="name">Nombre Completo</label>
                        <input
                          :class="`uc-input-style w-icon-left`"
                          id="name"
                          placeholder="Nombre Completo"
                          type="text"
                          v-model="name"
                        />
                        <i class="uc-icon">account_box</i>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="uc-form-group">
                        <label for="email">Correo</label>
                        <input
                          :class="`uc-input-style w-icon-left`"
                          id="email"
                          placeholder="Correo"
                          type="text"
                          v-model="email"
                        />
                        <i class="uc-icon">contact_mail</i>
                      </div>
                    </div>
                    <div class="col-md-12 mb-20">
                      <div class="uc-form-group">
                        <label for="question">Pregunta</label
                        ><textarea
                          class="uc-input-style w-icon-left"
                          placeholder="Envía tu pregunta"
                          rows="3"
                          v-model="question"
                        ></textarea
                        ><i class="uc-icon">help_outline</i>
                      </div>
                    </div>
                    <div class="col-md-12 text-right">
                      <a
                        href="javascript:void(0)"
                        class="uc-link uc-btn btn-inline ml-16"
                        @click="sendQuestion"
                      >
                        {{ sendText }}
                        <i class="uc-icon icon-shape--rounded">send</i>
                      </a>
                    </div>
                  </div>
                </transition>
              </div>
            </v-card>
          </v-tab-item>
        </v-tabs>
        <div class="rrss">
          <div class="rrss-column">
            <a
              :href="media"
              :key="key"
              style="text-decoration: none"
              target="_blank"
              v-for="(media, key) in stand.rrss"
              v-show="media"
            >
              <v-icon right> mdi-{{ key }} </v-icon>
            </a>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <hr class="uc-hr my-28" />
        <div class="row align-items-center">
          <div class="col-md-10 text-right"></div>
          <div class="col-md-2 text-right">
            <a
              href="javascript:void(0)"
              class="uc-link uc-btn btn-inline"
              @click="close"
            >
              Cerrar
              <i class="uc-icon icon-shape--rounded">close</i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <Modal
      :body="modal.body"
      :closeButton="modal.closeButton"
      :title="modal.title"
      :type="modal.type"
      caller="showResponseModal"
      class="uc-modal__response uc-modal__stand-response"
      v-if="showResponseModal"
    ></Modal>
  </div>
</template>

<script>
import CVForm from "@/components/CVForm.vue";
import Modal from "@/components/Modal.vue";
import Vue from "vue";
import VueYouTubeEmbed from "vue-youtube-embed";
import axios from "axios";
import { Carousel, Slide } from "vue-carousel";

Vue.use(VueYouTubeEmbed);

export default {
  name: "StandModal",
  components: { CVForm, Modal, Carousel, Slide },
  props: {
    stand: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      askQuestionText: "Enviar Pregunta",
      baseUrl: process.env.VUE_APP_REDES_BASE_PATH,
      email: null,
      imageHeight: 0,
      modal: {
        body: "",
        closeButton: false,
        confirmData: false,
        title: "Formulario Inscripción Entrevistas",
        type: "info",
      },
      name: null,
      qas: [],
      question: null,
      questionForm: false,
      sendText: "Enviar",
      showResponseModal: false,
      showTalk: false,
      vertical: false,
      videoText: "Charla",
      windowWidth: window.innerHeight,
    };
  },
  methods: {
    getImgUrl(url) {
      return `
        background-image: url('${url}');
        background-position: center;
        background-size: contain;
        height: ${this.imageHeight}px;
        width: 100%;
      `;
    },
    allowQuestion() {
      return false;
    },
    handleQuestionForm() {
      this.questionForm = !this.questionForm;
      this.askQuestionText = this.questionForm ? "Ocultar" : "Enviar Pregunta";
    },
    sendQuestion() {
      this.sendText = "Enviando...";

      axios
        .post(
          `${process.env.VUE_APP_REDES_ENDPOINT}/fairs/qa`,
          {
            name: this.name,
            email: this.email,
            question: this.question,
            employer_id: this.stand.id,
          },
          {
            withCredentials: false,
          }
        )
        .then((response) => {
          this.modal.body = response.data.message;
          this.modal.type = "warning";

          if (response.data.success) {
            this.handleQuestionForm();
            this.name = null;
            this.email = null;
            this.question = null;
            this.modal.closeButton = true;
            this.modal.body = response.data.message;
            this.modal.type = "success";
          }
        })
        .catch((error) => {
          const status = error.response.status;
          switch (status) {
            case 409:
              this.modal.closeButton = true;
              this.modal.body = error.message;
              this.modal.type = "warning";
              break;
            default:
              this.modal.closeButton = true;
              this.modal.body = error.message;
              this.modal.type = "error";
              break;
          }
        })
        .then(() => {
          this.showResponseModal = true;
          this.showModal = false;
          this.sendText = "Enviar";
        });
    },
    send() {
      this.$parent.standId = this.stand.id;
      this.$parent.send();
    },
    close() {
      this.$parent.showStandModal = false;
      this.$parent.$parent.$children[0].$children[0].$refs.live.player.unMute();
    },
    setImage() {
      return `
        background-image: url('${this.baseUrl}/${this.stand.logoPath}');
      `;
    },
    closeModal(event) {
      if (event.keyCode === 27) {
        if (this.$parent.showModal) {
          this.$parent.showModal = false;
          return;
        }
        this.close();
      }
    },
    keepOpen(event) {
      event.stopPropagation();
    },
    stopVideo() {
      if (!Object.keys(this.$refs)) {
        return;
      }

      if ("video" in this.$refs && "player" in this.$refs.video) {
        this.$refs.video.player.stopVideo();
      }

      if ("talk" in this.$refs && "player" in this.$refs.talk) {
        this.$refs.talk.player.stopVideo();
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth;
      this.imageHeight =
        window.document.getElementsByClassName(
          "uc-message_body"
        )[0].childNodes[0].clientHeight;
    },
  },
  // watch: {
  //   windowWidth(newWidth) {
  //     if (newWidth < 1000) {
  //       this.vertical = false;
  //     }
  //     if (newWidth >= 1000) {
  //       this.vertical = true;
  //     }
  //   },
  // },
  mounted() {
    document
      .querySelector(".modal-footer .row .col-md-10")
      .appendChild(document.querySelector("[role='tablist']"));
    document.addEventListener("keyup", this.closeModal);
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });

    axios
      .get(`${process.env.VUE_APP_REDES_ENDPOINT}/fairs/qa/${this.stand.id}`, {
        withCredentials: false,
      })
      .then((response) => {
        this.qas = response.data.qas;
      });
    this.imageHeight =
      window.document.getElementsByClassName(
        "uc-message_body"
      )[0].childNodes[0].clientHeight;
  },
  destroyed() {
    document.removeEventListener("keyup", this.closeModal);
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    cssVars() {
      return {
        "--bg-color": this.stand.color,
        display: "block",
      };
    },
  },
};
</script>

<style lang="sass">
.stand-bg
  background-color: var(--bg-color)

.uc-modal.stand-modal
  .v-tab--active
    background-color: var(--bg-color)
    color: #fff !important
    .uc-icon.mdi
      color: #fff !important
  .uc-modal_content
    height: 100%
    min-height: 600px
    width: 95% !important
  .uc-message_body
    height: 100%
    padding-bottom: 148px !important
    padding-left: 48px !important
    padding-right: 48px !important
    padding-top: 250px !important
    position: absolute
    top: 10px
    width: 100%
    .v-tabs
      display: inline-grid
      width: 95%
    .rrss
      display: inline-grid
      position: absolute
      width: 5%
      .rrss-column
        display: grid
        justify-content: center
        a
          padding-bottom: 10px
  .modal-header
    background-color: var(--bg-color)
    border-top-left-radius: 4px
    border-top-right-radius: 4px
    height: auto
    padding-bottom: 20px
  .modal-footer
    background-color: #fff
    bottom: 20px
    padding: 0 48px
    position: absolute
    width: 100%
  .v-tabs
    height: 100%
  .v-tabs-items
    max-height: 100%
    overflow-y: auto
    .v-window__container
      height: 95%
  .v-tabs.v-tabs--vertical
    padding-bottom: 0
    .v-tabs-bar
      width: 450px
    .v-tabs-bar__content
      margin-bottom: 10px
      padding: 10px
  .v-tabs--vertical
    .v-tab
      font-weight: bold
      justify-content: left !important
      max-width: 100% !important

  .v-tabs--vertical
    .v-tab--active
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75)
      height: 80px !important

  .v-tabs-slider-wrapper
    width: 0px !important

@media (min-width: 1150px)
  .uc-modal.stand-modal
    .modal-header
      height: 150px
      padding-bottom: 0
    .uc-message_body
      padding-top: 210px !important

@media (min-width: 1220px)
  .uc-modal.stand-modal
    .modal-header
      height: 150px
      padding-bottom: 0

@media (min-width: 576px)
  .uc-modal.stand-modal
    .uc-message_body
      top: 10px

.stand-modal
  video
    width: 100%
  .youtube-video > iframe
    height: 100%
    width: 100%

.stand-modal_logo
  display: inline-block

.stand-modal_circle
  background-color: #fff
  border-radius: 50%
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 15px
  margin: auto
  position: relative
  height: 160px
  width: 160px

.stand-modal_square
  background-color: #fff
  box-shadow: rgb(0 0 0 / 66%) 0px 0px 20px 3px
  margin: auto
  position: relative
  height: 160px
  width: 160px

.stand-modal_img
  background-position: center
  background-repeat: no-repeat
  background-size: contain
  height: 100%
  margin: auto
  width: 90%

.social-media
  bottom: 20px
  margin-left: -10px
  position: absolute
  width: 100%

.uc-icon.mdi
  color: rgba(0, 0, 0, 0.54)

.slide-fade-enter-active
  transition: all .5s ease

.slide-fade-enter, .slide-fade-leave-to
  transform: translateX(10px)
  opacity: 0

.uc-modal__stand-response.uc-modal__stand-response
  .uc-modal_content
    min-height: auto !important
    width: 50% !important
    height: 280px
  .uc-message_body
    padding: 48px !important

.v-timeline
  .row
    padding: 15px 0

.v-application--wrap
  min-height: 100% !important

.v-window__next
  margin-right: 30px
  right: 0
</style>
