<template>
  <div class="col-md-12">
    <div class="uc-form-group">
      <v-menu
        ref="menu"
        :close-on-content-click="experienceDate.length > 1"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <div>
            <label :for="`experienceDate_${experienceKey}`">Periodo</label>
            <input
              :class="`uc-input-style w-icon-left`"
              :placeholder="experienceDatePlaceholder"
              :id="`experienceDate_${experienceKey}`"
              readonly
              type="text"
              v-bind="attrs"
              v-on="on"
              v-tooltip="
                `Si aún continúas desepeñando esto, sólo marca el periodo de comienzo`
              "
            />
            <i class="uc-icon">account_box</i>
          </div>
        </template>
        <v-date-picker
          :max="new Date().toISOString().substr(0, 10)"
          @input="
            setFieldArray({
              field: `experiences`,
              id: `experience_${experienceKey}`,
              value: {
                id: `date`,
                value: experienceDate,
              },
            })
          "
          v-model="experienceDate"
          no-title
          range
          type="month"
        ></v-date-picker>
      </v-menu>
    </div>

    <div class="uc-form-group">
      <label for="experienceCompany">Lugar donde desempeñó el cargo</label>
      <input
        :id="`experienceCompany_${experienceKey}`"
        @change="
          setFieldArray({
            id: `experience_${experienceKey}`,
            value: {
              id: `company`,
              value: experienceCompany,
            },
          })
        "
        v-model="experienceCompany"
        type="text"
        :class="`uc-input-style w-icon-left`"
        placeholder
      />
      <i class="uc-icon">account_box</i>
    </div>

    <div class="uc-form-group">
      <label for="experienceName">Cargo</label>
      <input
        :id="`experienceName_${experienceKey}`"
        @change="
          setFieldArray({
            id: `experience_${experienceKey}`,
            value: {
              id: `name`,
              value: experienceName,
            },
          })
        "
        v-model="experienceName"
        type="text"
        :class="`uc-input-style w-icon-left`"
        placeholder="Cargo"
      />
      <i class="uc-icon">account_box</i>
    </div>

    <div class="uc-form-group">
      <label for="experienceName">Descripción del cargo</label>
      <textarea
        class="uc-input-style w-icon-left"
        rows="3"
        placeholder="Narra tu experiencia en el cargo"
        @change="
          setFieldArray({
            id: `experience_${experienceKey}`,
            value: {
              id: `description`,
              value: experienceDescription,
            },
          })
        "
        v-model="experienceDescription"
      ></textarea>
      <i class="uc-icon">account_box</i>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapMutations } from "vuex";
import { utils } from "@/mixins/utils.js";

export default {
  mixins: [utils],
  props: {
    experienceKey: { type: Number, default: 0 },
    universitiesList: Array,
    experienceContent: Array,
  },
  data() {
    return {
      experienceDate: [],
      experienceDatePlaceholder: "Desde - Hasta",
      experienceName: null,
      experienceCompany: null,
      experienceDescription: null,
    };
  },
  beforeMount() {
    for (var i in this.experienceContent) {
      const element = this.experienceContent[i];
      this[`experience${this.capitalize(element.id)}`] = element.value;
    }
  },
  methods: {
    ...mapMutations("CVForm", ["setFieldArray"]),
  },
  watch: {
    experienceDate() {
      switch (this.experienceDate.length) {
        case 1:
          this.experienceDatePlaceholder = `Desde ${moment(
            this.experienceDate[0] + "-01"
          ).format("MMM/Y")} - Actualidad`;
          break;
        case 2:
          this.experienceDatePlaceholder = `Desde ${moment(
            this.experienceDate[0] + "-01"
          ).format("MMM/Y")} - Hasta ${moment(
            this.experienceDate[1] + "-01"
          ).format("MMM/Y")}`;
          break;
        default:
          this.experienceDatePlaceholder = null;
      }
    },
  },
};
</script>
