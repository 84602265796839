var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"uc-form-group"},[_c('label',{attrs:{"for":("assistantshipTeacher_" + _vm.assistantshipKey)}},[_vm._v("Profesor")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.assistantshipTeacher),expression:"assistantshipTeacher"}],ref:("assistantshipTeacher_" + _vm.assistantshipKey),class:"uc-input-style w-icon-left",attrs:{"id":("assistantshipTeacher_" + _vm.assistantshipKey),"type":"text","placeholder":"Profesor"},domProps:{"value":(_vm.assistantshipTeacher)},on:{"change":function($event){return _vm.setFieldArray({
            field: "assistantships",
            id: ("assistantship_" + _vm.assistantshipKey),
            value: {
              id: "teacher",
              value: _vm.assistantshipTeacher,
            },
        })},"input":function($event){if($event.target.composing){ return; }_vm.assistantshipTeacher=$event.target.value}}}),_c('i',{staticClass:"uc-icon"},[_vm._v("account_box")])]),_c('div',{staticClass:"uc-form-group"},[_c('label',{attrs:{"for":("assistantshipSubject_" + _vm.assistantshipKey)}},[_vm._v("Curso")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.assistantshipSubject),expression:"assistantshipSubject"}],ref:("assistantshipSubject_" + _vm.assistantshipKey),class:"uc-input-style w-icon-left",attrs:{"id":("assistantshipSubject_" + _vm.assistantshipKey),"type":"text","placeholder":"Curso"},domProps:{"value":(_vm.assistantshipSubject)},on:{"change":function($event){return _vm.setFieldArray({
          field: "assistantships",
          id: ("assistantship_" + _vm.assistantshipKey),
          value: {
            id: "subject",
            value: _vm.assistantshipSubject,
          },
        })},"input":function($event){if($event.target.composing){ return; }_vm.assistantshipSubject=$event.target.value}}}),_c('i',{staticClass:"uc-icon"},[_vm._v("account_box")])]),_c('div',{staticClass:"uc-form-group"},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":_vm.assistantshipDate.length > 1,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',[_c('label',{attrs:{"for":("assistantshipDate_" + _vm.assistantshipKey)}},[_vm._v("Periodo")]),_c('input',_vm._g(_vm._b({directives:[{name:"tooltip",rawName:"v-tooltip",value:(
              "Si aún continúas desepeñando esto, sólo marca el periodo de comienzo"
            ),expression:"\n              `Si aún continúas desepeñando esto, sólo marca el periodo de comienzo`\n            "}],class:"uc-input-style w-icon-left",attrs:{"id":("assistantshipDate_" + _vm.assistantshipKey),"readonly":"","type":"text","placeholder":_vm.assistantshipDatePlaceholder}},'input',attrs,false),on)),_c('i',{staticClass:"uc-icon"},[_vm._v("account_box")])])]}}])},[_c('v-date-picker',{attrs:{"max":new Date().toISOString().substr(0, 10),"no-title":"","range":"","type":"month"},on:{"input":function($event){return _vm.setFieldArray({
            field: "assistantships",
            id: ("assistantship_" + _vm.assistantshipKey),
            value: {
              id: "date",
              value: _vm.assistantshipDate,
            },
          })}},model:{value:(_vm.assistantshipDate),callback:function ($$v) {_vm.assistantshipDate=$$v},expression:"assistantshipDate"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }