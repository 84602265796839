<template>
  <div class="col">
    <div
      class="stand-box text-center"
      @click="openModal"
      :style="setBoxColor(stand)"
      :onmouseout="setBoxColorOut(stand)"
      :onmouseover="setBoxColorOver(stand)"
    >
      <div class="stand-box_body">
        <div>{{ stand.name }}</div>
      </div>
      <div class="stand-box_footer">
        <div class="stand-box-text">
          <strong style="color: #fff; font-size: 1em">Ingresa Aquí</strong>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    stand: Object,
  },
  methods: {
    setImage(stand) {
      if (stand.logoPath === null) {
        return;
      }
      return `
        background-image: url('${process.env.VUE_APP_REDES_BASE_PATH}/${stand.logoPath}');
        width: ${stand.width};
      `;
    },
    setBoxColor(stand) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
        stand.color
      );
      const r = parseInt(result[1], 16);
      const g = parseInt(result[2], 16);
      const b = parseInt(result[3], 16);

      return `
        background-color: rgba(${r}, ${g}, ${b}, .6);
      `;
    },
    setBoxColorOut(stand) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
        stand.color
      );
      const r = parseInt(result[1], 16);
      const g = parseInt(result[2], 16);
      const b = parseInt(result[3], 16);

      return `this.style.backgroundColor = "rgba(${r}, ${g}, ${b}, .6)";`;
    },
    setBoxColorOver(stand) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
        stand.color
      );
      const r = parseInt(result[1], 16);
      const g = parseInt(result[2], 16);
      const b = parseInt(result[3], 16);

      return `this.style.backgroundColor = "rgba(${r}, ${g}, ${b}, 1)";`;
    },
    openModal() {
      this.$parent.$parent.$children[0].$children[0].$refs.live.player.mute();
      this.$parent.handleModal(this.stand);
    },
  },
};
</script>

<style lang="sass">
.stand-box
  box-shadow: rgb(0 0 0 / 60%) 0 0 15px
  cursor: pointer
  color: white
  display: inline-block
  margin: 20px 15px
  position: relative
  transition: transform .2s
  z-index: 1
  .stand-box_body
    display: flex
    flex-flow: column
    justify-content: center
    height: 6em
    width: 7.8em
  .stand-box_footer
    height: 1.5em
  .stand-box_footer
    strong
      display: none
  &:hover
    transform: scale(1.3)
    z-index: 50
    .stand-box_footer
        strong
          display: block

// .stand-box_square:hover
//   .stand-box-text
//     display: display

// .stand-box:hover
//   .stand-box_body
//     display: block

// .stand-box_square
//   box-shadow: rgb(0 0 0 / 60%) 0 0 15px
//   height: 6em
//   margin: auto
//   position: relative
//   top: -6em
//   width: 7.8em

// .stand-box-img
//   background-position: center
//   background-repeat: no-repeat
//   background-size: contain
//   height: 100%
//   margin: auto

// .sstand-box_body
//   position: relative
//   top: 60px
//   width: 7.8em
//   height: 25px
</style>
