var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"uc-form-group"},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":_vm.experienceDate.length > 1,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('label',{attrs:{"for":("experienceDate_" + _vm.experienceKey)}},[_vm._v("Periodo")]),_c('input',_vm._g(_vm._b({directives:[{name:"tooltip",rawName:"v-tooltip",value:(
              "Si aún continúas desepeñando esto, sólo marca el periodo de comienzo"
            ),expression:"\n              `Si aún continúas desepeñando esto, sólo marca el periodo de comienzo`\n            "}],class:"uc-input-style w-icon-left",attrs:{"placeholder":_vm.experienceDatePlaceholder,"id":("experienceDate_" + _vm.experienceKey),"readonly":"","type":"text"}},'input',attrs,false),on)),_c('i',{staticClass:"uc-icon"},[_vm._v("account_box")])])]}}])},[_c('v-date-picker',{attrs:{"max":new Date().toISOString().substr(0, 10),"no-title":"","range":"","type":"month"},on:{"input":function($event){return _vm.setFieldArray({
            field: "experiences",
            id: ("experience_" + _vm.experienceKey),
            value: {
              id: "date",
              value: _vm.experienceDate,
            },
          })}},model:{value:(_vm.experienceDate),callback:function ($$v) {_vm.experienceDate=$$v},expression:"experienceDate"}})],1)],1),_c('div',{staticClass:"uc-form-group"},[_c('label',{attrs:{"for":"experienceCompany"}},[_vm._v("Lugar donde desempeñó el cargo")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.experienceCompany),expression:"experienceCompany"}],class:"uc-input-style w-icon-left",attrs:{"id":("experienceCompany_" + _vm.experienceKey),"type":"text","placeholder":""},domProps:{"value":(_vm.experienceCompany)},on:{"change":function($event){return _vm.setFieldArray({
          id: ("experience_" + _vm.experienceKey),
          value: {
            id: "company",
            value: _vm.experienceCompany,
          },
        })},"input":function($event){if($event.target.composing){ return; }_vm.experienceCompany=$event.target.value}}}),_c('i',{staticClass:"uc-icon"},[_vm._v("account_box")])]),_c('div',{staticClass:"uc-form-group"},[_c('label',{attrs:{"for":"experienceName"}},[_vm._v("Cargo")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.experienceName),expression:"experienceName"}],class:"uc-input-style w-icon-left",attrs:{"id":("experienceName_" + _vm.experienceKey),"type":"text","placeholder":"Cargo"},domProps:{"value":(_vm.experienceName)},on:{"change":function($event){return _vm.setFieldArray({
          id: ("experience_" + _vm.experienceKey),
          value: {
            id: "name",
            value: _vm.experienceName,
          },
        })},"input":function($event){if($event.target.composing){ return; }_vm.experienceName=$event.target.value}}}),_c('i',{staticClass:"uc-icon"},[_vm._v("account_box")])]),_c('div',{staticClass:"uc-form-group"},[_c('label',{attrs:{"for":"experienceName"}},[_vm._v("Descripción del cargo")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.experienceDescription),expression:"experienceDescription"}],staticClass:"uc-input-style w-icon-left",attrs:{"rows":"3","placeholder":"Narra tu experiencia en el cargo"},domProps:{"value":(_vm.experienceDescription)},on:{"change":function($event){return _vm.setFieldArray({
          id: ("experience_" + _vm.experienceKey),
          value: {
            id: "description",
            value: _vm.experienceDescription,
          },
        })},"input":function($event){if($event.target.composing){ return; }_vm.experienceDescription=$event.target.value}}}),_c('i',{staticClass:"uc-icon"},[_vm._v("account_box")])])])}
var staticRenderFns = []

export { render, staticRenderFns }