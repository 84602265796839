<template>
  <div style="position: relative">
    <div class="fair-bg"></div>
    <div class="fair-stands">
      <div class="row pt-80 pb-60">
        <StandCard v-for="(stand, key) in stands" :key="key" :stand="stand" />
      </div>
      <div class="row">
        <div class="col text-center">
          <LLMCard />
        </div>
        <div class="col text-center">
          <CECard />
        </div>
      </div>
      <StandModal :stand="stand" v-if="showStandModal" />
      <LLMModal v-if="showLLMModal" />
      <CEModal v-if="showCEModal" />
      <Modal
        :closeButton="true"
        class="modal__stands-background text-center"
        style="color: #696868"
        caller="showStandsModal"
        title="Bienvenidos a la Feria del Trabajo Derecho UC<br><i>ONLINE</i>"
        type="info"
        icon
        v-if="showStandsModal"
      >
        <template slot="body">
          <img class="stands-background" :src="setStandsBackground()" />
        </template>
      </Modal>
      <Modal
        :body="modal.body"
        :closeButton="modal.closeButton"
        :title="modal.title"
        :type="modal.type"
        caller="showResponseModal"
        class="uc-modal__response"
        v-if="showResponseModal"
      ></Modal>
    </div>
    <div class="row">
      <div class="col mx-12 mt-40">
        <ExhibitorsCarousel />
      </div>
    </div>
  </div>
</template>

<script>
import CECard from "@/components/CECard.vue";
import CEModal from "@/components/CEModal.vue";
import Cookies from "js-cookie";
import ExhibitorsCarousel from "@/components/ExhibitorsCarousel.vue";
import LLMCard from "@/components/LLMCard.vue";
import LLMModal from "@/components/LLMModal.vue";
import Modal from "@/components/Modal.vue";
import StandCard from "@/components/StandCard.vue";
import StandModal from "@/components/StandModal.vue";
import axios from "axios";

export default {
  components: {
    CECard,
    CEModal,
    ExhibitorsCarousel,
    LLMCard,
    LLMModal,
    Modal,
    StandCard,
    StandModal,
  },
  data() {
    return {
      showLawModal: false,
      showLLMModal: false,
      showCEModal: false,
      showStandModal: false,
      showStandsModal: false,
      stands: [],
      standsGrouped: [],
      stand: null,
      showModal: false,
      showResponseModal: false,
      windowWidth: 0,
      sendText: "Enviar",
      standId: null,
      standsMap: {
        albagli: 113,
        cariola: 43,
        chirgwin: 72,
        cmf: 357,
        contraloria: 280,
        contreras: 130,
        ey: 299,
        fischer: 399,
        probono: 297,
        idealis: 261,
        morales: 83,
        pagbam: 24,
        ppu: 26,
        baraona: 407,
        cyj: 272,
        cuatrecasas: 391,
        jdf: 203,
        kehr: 392,
        ortuzar: 409,
        rf: 309,
        ajudicia: 286,
        alessandri: 78,
        bye: 71,
        bsilva: 440,
        cam: 281,
        carey: 42,
        claro: 18,
        cabogados: 358,
        cdf: 165,
        capj: 233,
        dla: 73,
        mpublico: 274,
        fne: 98,
        fdv: 341,
        garrigues: 2,
        guerrero: 100,
        moraga: 252,
        prieto: 99,
        tc: 67,
        ulloa: 175,
        deloitte: 253,
        wlaw: 437,
      },
      modal: {
        body: "",
        closeButton: false,
        confirmData: false,
        formSent: false,
        title: "Formulario Inscripción Entrevistas",
        type: "info",
      },
    };
  },
  mounted() {
    if (this.$route.query.stand) {
      let standName = this.$route.query.stand;
      let standId = this.standsMap[standName];
      let stand = this.stands.find((stand) => stand.id == standId);
      this.handleModal(stand);
    }
  },
  async created() {
    const standsResponse = await axios.get(
      `${process.env.VUE_APP_REDES_ENDPOINT}/fairs/stands`,
      {
        withCredentials: false,
      }
    );

    const { data } = standsResponse;
    if (data.success) {
      this.stands = data.stands;
    }

    this.$nextTick(function () {
      window.addEventListener("resize", this.getWindowWidth);

      //Init
      this.getWindowWidth();
    });

    axios
      .get(`${process.env.VUE_APP_REDES_ENDPOINT}/universities`, {
        withCredentials: false,
      })
      .then((response) => {
        const list = "universitiesList";
        const values = response.data;
        this.$store.commit("CVForm/setList", { list, values });
      });

    axios
      .get(
        `${process.env.VUE_APP_REDES_ENDPOINT}/areas?q[id_area_interes_ni]=1,2,6,7,9,10,12,13`,
        {
          withCredentials: false,
        }
      )
      .then((response) => {
        const list = "areasList";
        const values = response.data;
        this.$store.commit("CVForm/setList", { list, values });
      });

    axios
      .get(`${process.env.VUE_APP_REDES_ENDPOINT}/regions`, {
        withCredentials: false,
      })
      .then((response) => {
        const list = "regionsList";
        const values = response.data;
        this.$store.commit("CVForm/setList", { list, values });
      });
  },
  destroyed() {
    window.removeEventListener("resize", this.getWindowWidth);
  },
  methods: {
    setStandsBackground() {
      return `${process.env.VUE_APP_REDES_BASE_PATH}/uploads/feria/modal.jpeg`;
    },
    send() {
      if (this.standId == null) {
        return;
      }
      this.$children[38].sendText = "Enviando...";
      this.$store.commit("CVForm/resetRequired");

      const fields = this.$store.state.CVForm.fields;

      Cookies.set("fields", JSON.stringify(fields), { expires: 1 / 24 });
      this.$store.commit("CVForm/resetForm");
      fields["standId"] = this.standId;

      axios
        .post(
          `${process.env.VUE_APP_REDES_ENDPOINT}/applicants_cv`,
          {
            payload: fields,
          },
          {
            withCredentials: false,
          }
        )
        .then((response) => {
          this.modal.body = response.data.message;
          this.modal.type = "warning";

          if (response.data.success) {
            this.modal.formSent = true;
            this.modal.closeButton = true;
            this.modal.body = response.data.message;
            this.modal.type = "success";
          }
        })
        .catch((error) => {
          switch (error.response.status) {
            case 409:
              this.modal.formSent = true;
              this.modal.closeButton = true;
              this.modal.body = error.response.data.message;
              this.modal.type = "warning";
              break;
            default:
              this.modal.formSent = true;
              this.modal.closeButton = true;
              this.modal.body = error.response.data.message;
              this.modal.type = "error";
              break;
          }
        })
        .then(() => {
          this.showResponseModal = true;
          this.showModal = false;
          this.$children[38].sendText = "Enviar";
        });
    },
    groupStands() {
      this.standsGrouped = [];
      switch (true) {
        case this.windowWidth < 1300:
          this.standsGrouped.push(
            this.stands.slice(0, 4),
            this.stands.slice(4, 8),
            this.stands.slice(8, 8),
            this.stands.slice(12, 16),
            this.stands.slice(16, 20),
            this.stands.slice(20, 24),
            this.stands.slice(24, 28),
            this.stands.slice(28, 32),
            this.stands.slice(32, 36),
            this.stands.slice(36, 40),
            this.stands.slice(40, 44),
            this.stands.slice(44, 48),
            this.stands.slice(48, 52),
            this.stands.slice(52, 56),
            this.stands.slice(56)
          );
          break;
        case this.windowWidth < 1500:
          this.standsGrouped.push(
            this.stands.slice(0, 6),
            this.stands.slice(6, 12),
            this.stands.slice(12, 18),
            this.stands.slice(18, 24),
            this.stands.slice(24, 30),
            this.stands.slice(30, 36),
            this.stands.slice(36, 42),
            this.stands.slice(42, 48),
            this.stands.slice(48, 54),
            this.stands.slice(54)
          );
          break;
        case this.windowWidth < 1780:
          this.standsGrouped.push(
            this.stands.slice(0, 7),
            this.stands.slice(7, 14),
            this.stands.slice(14, 21),
            this.stands.slice(21, 28),
            this.stands.slice(28, 35),
            this.stands.slice(35, 42),
            this.stands.slice(42, 49),
            this.stands.slice(49, 56),
            this.stands.slice(56)
          );
          break;
        default:
          this.standsGrouped.push(
            this.stands.slice(0, 9),
            this.stands.slice(9, 18),
            this.stands.slice(18, 27),
            this.stands.slice(27, 36),
            this.stands.slice(36, 45),
            this.stands.slice(45)
          );
      }
    },
    handleModal(stand) {
      this.stand = stand;
      this.showStandModal = true;
    },
    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth;
      this.groupStands();
    },
  },
};
</script>

<style lang="sass">
.subheading-container
  em
    font-style: normal !important
.fair-stands
  padding-left: 50px
  padding-right: 50px
  position: relative
  width: 100%

.fair-bg
  background-image: url('http://intranetredes.derecho.uc.cl/uploads/documentos_compartidos/centro_de_extension.JPG')
  background-position: center
  background-size: cover
  height: 100%
  opacity: 0.3
  position: absolute
  width: 100%

.modal__stands-background
  .uc-modal_content
    width: 80% !important

.stands-background
  height: auto
  margin: 0 5%
  width: 90%

.uc-modal
  padding-top: 20px !important
  padding-bottom: 50px !important

.uc-modal .uc-modal_content
  width: 60%

.uc-modal__response
  .uc-modal_content
    width: 60% !important

.uc-input-style.w-icon-left
  background-position: left 16px center !important
  padding: 12px 40px

textarea.uc-input-style.w-icon-left
  padding: 30px 40px

select[multiple="multiple"]
  height: auto

select[multiple="multiple"] + .uc-icon
  top: 70px

.w-icon-left + .uc-icon
  left: 10px
  position: absolute
  top: 42px

.subheading-container
  background: linear-gradient(90deg, rgb(43, 13, 87) 0%, rgb(102, 9, 74) 100%)
  -webkit-background-clip: text
  -webkit-mask-image: linear-gradient(rgb(43, 13, 87), rgb(102, 9, 74))
  -webkit-text-fill-color: transparent
  h1
    font-size: 4vw
  h2
    font-size: 3.5vw
    font-weight: 400
  h3
    font-size: 3vw
</style>
