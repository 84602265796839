<template>
  <div class="col-md-12">
    <div class="uc-form-group">
      <label :for="`masterUniversity_${masterKey}`">Universidad</label>
      <v-select
        :class="`uc-input-style w-icon-left`"
        :create-option="(masterUniversity) => ({ id: masterUniversity, name: masterUniversity })"
        :options="universitiesList"
        :reduce="(university) => university.id"
        @input="
          setFieldArray({
            field: `masters`,
            id: `master_${masterKey}`,
            value: {
              id: `university`,
              value: masterUniversity,
            },
          })
        "
        :id="`masterUniversity_${masterKey}`"
        label="name"
        placeholder="Universidad"
        push-tags
        taggable
        v-model="masterUniversity"
        v-tooltip="
          `Si tu universidad no está en el listado, sólo ecríbela y presiona enter`
        "
      >
        <span slot="no-options">Búsqueda no encontrada...</span>
      </v-select>
      <i class="uc-icon">school</i>
    </div>

    <div class="uc-form-group">
      <label :for="`masterName_${masterKey}`">Nombre</label>
      <input
        :id="`masterName_${masterKey}`"
        @change="
          setFieldArray({
            field: `masters`,
            id: `master_${masterKey}`,
            value: {
              id: `name`,
              value: masterName,
            },
          })
        "
        type="text"
        :class="`uc-input-style w-icon-left`"
        placeholder="Nombre"
        v-model="masterName"
      />
      <i class="uc-icon">account_box</i>
    </div>

    <div class="uc-form-group">
      <label :for="`masterYear_${masterKey}`">Año Término</label>
      <v-select
        :class="`uc-input-style w-icon-left`"
        :options="years"
        :reduce="(year) => year.id"
        @input="
          setFieldArray({
            field: `masters`,
            id: `master_${masterKey}`,
            value: {
              id: `year`,
              value: masterYear,
            },
          })
        "
        :id="`masterYear_${masterKey}`"
        label="year"
        placeholder="Seleccione..."
        v-model="masterYear"
      >
        <span slot="no-options">Búsqueda no encontrada...</span>
      </v-select>
      <i class="uc-icon">school</i>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import vSelect from "vue-select";
import { mapMutations } from "vuex";
import { utils } from "@/mixins/utils.js";

export default {
  mixins: [utils],
  components: { "v-select": vSelect },
  props: {
    masterKey: { type: Number, default: 0 },
    universitiesList: Array,
    masterContent: Array,
  },
  data() {
    return {
      masterDate: [],
      masterDatePlaceholder: "Desde - Hasta",
      masterName: null,
      masterUniversity: null,
      masterYear: null,
      years: [],
    };
  },
  beforeMount() {
    for (var i in this.masterContent) {
      const element = this.masterContent[i];
      this[`master${this.capitalize(element.id)}`] = element.value;
    }
    this.years = this.fillYears();
  },
  methods: {
    ...mapMutations("CVForm", ["setFieldArray"]),

    fillYears() {
      const years = [];
      const currentYear = new Date().getFullYear();

      for (let i = 0; i < 45; i++) {
        years.push({
          id: currentYear - i,
          year: currentYear - i,
        });
      }

      return years;
    },
  },
  watch: {
    masterDate() {
      switch (this.masterDate.length) {
        case 1:
          this.masterDatePlaceholder = `Desde ${moment(
            this.masterDate[0] + "-01"
          ).format("MMM/Y")} - Actualidad`;
          break;
        case 2:
          this.masterDatePlaceholder = `Desde ${moment(
            this.masterDate[0] + "-01"
          ).format("MMM/Y")} - Hasta ${moment(
            this.masterDate[1] + "-01"
          ).format("MMM/Y")}`;
          break;
        default:
          this.masterDatePlaceholder = null;
      }
    },
  },
};
</script>
