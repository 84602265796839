<template>
  <div class="col-md-12">
    <div class="uc-form-group">
      <label :for="`phdUniversity_${phdKey}`">Universidad</label>
      <v-select
        :class="`uc-input-style w-icon-left`"
        :create-option="(phdUniversity) => ({ id: phdUniversity, name: phdUniversity })"
        :options="universitiesList"
        :reduce="(university) => university.id"
        @input="
          setFieldArray({
            field: `phds`,
            id: `phd_${phdKey}`,
            value: {
              id: `university`,
              value: phdUniversity,
            },
          })
        "
        :id="`phdUniversity_${phdKey}`"
        label="name"
        placeholder="Universidad"
        push-tags
        taggable
        v-model="phdUniversity"
        v-tooltip="
          `Si tu universidad no está en el listado, sólo ecríbela y presiona enter`
        "
      >
        <span slot="no-options">Búsqueda no encontrada...</span>
      </v-select>
      <i class="uc-icon">school</i>
    </div>

    <div class="uc-form-group">
      <label :for="`phdName_${phdKey}`">Nombre</label>
      <input
        :id="`phdName_${phdKey}`"
        @change="
          setFieldArray({
            field: `phds`,
            id: `phd_${phdKey}`,
            value: {
              id: `name`,
              value: phdName,
            },
          })
        "
        type="text"
        :class="`uc-input-style w-icon-left`"
        placeholder="Nombre"
        v-model="phdName"
      />
      <i class="uc-icon">account_box</i>
    </div>

    <div class="uc-form-group">
      <label :for="`phdYear_${phdKey}`">Año Término</label>
      <v-select
        :class="`uc-input-style w-icon-left`"
        :options="years"
        :reduce="(year) => year.id"
        @input="
          setFieldArray({
            field: `phds`,
            id: `phd_${phdKey}`,
            value: {
              id: `year`,
              value: phdYear,
            },
          })
        "
        :id="`phdYear_${phdKey}`"
        label="year"
        placeholder="Seleccione..."
        v-model="phdYear"
      >
        <span slot="no-options">Búsqueda no encontrada...</span>
      </v-select>
      <i class="uc-icon">school</i>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import vSelect from "vue-select";
import { mapMutations } from "vuex";
import { utils } from "@/mixins/utils.js";

export default {
  mixins: [utils],
  components: { "v-select": vSelect },
  props: {
    id: Number,
    phdKey: { type: Number, default: 0 },
    universitiesList: Array,
    phdContent: Array,
  },
  data() {
    return {
      phdDate: [],
      phdDatePlaceholder: "Desde - Hasta",
      phdName: null,
      phdUniversity: null,
      phdYear: null,
      years: [],
    };
  },
  beforeMount() {
    for (var i in this.phdContent) {
      const element = this.phdContent[i];
      this[`phd${this.capitalize(element.id)}`] = element.value;
    }
    this.years = this.fillYears();
  },
  methods: {
    ...mapMutations("CVForm", ["setFieldArray"]),

    fillYears() {
      const years = [];
      const currentYear = new Date().getFullYear();

      for (let i = 0; i < 45; i++) {
        years.push({
          id: currentYear - i,
          year: currentYear - i,
        });
      }

      return years;
    },
  },
  watch: {
    phdDate() {
      switch (this.phdDate.length) {
        case 1:
          this.phdDatePlaceholder = `Desde ${moment(
            this.phdDate[0] + "-01"
          ).format("MMM/Y")} - Actualidad`;
          break;
        case 2:
          this.phdDatePlaceholder = `Desde ${moment(
            this.phdDate[0] + "-01"
          ).format("MMM/Y")} - Hasta ${moment(this.phdDate[1] + "-01").format(
            "MMM/Y"
          )}`;
          break;
        default:
          this.phdDatePlaceholder = null;
      }
    },
  },
};
</script>
