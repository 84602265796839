<template>
  <div class="col-md-12">
    <div class="uc-form-group">
      <label :for="`assistantshipTeacher_${assistantshipKey}`">Profesor</label>
      <input
        v-model="assistantshipTeacher"
        :id="`assistantshipTeacher_${assistantshipKey}`"
        :ref="`assistantshipTeacher_${assistantshipKey}`"
        @change="
          setFieldArray({
              field: `assistantships`,
              id: `assistantship_${assistantshipKey}`,
              value: {
                id: `teacher`,
                value: assistantshipTeacher,
              },
          })
        "
        type="text"
        :class="`uc-input-style w-icon-left`"
        placeholder="Profesor"
      />
      <i class="uc-icon">account_box</i>
    </div>
    <div class="uc-form-group">
      <label :for="`assistantshipSubject_${assistantshipKey}`">Curso</label>
      <input
        v-model="assistantshipSubject"
        :id="`assistantshipSubject_${assistantshipKey}`"
        :ref="`assistantshipSubject_${assistantshipKey}`"
        @change="
          setFieldArray({
            field: `assistantships`,
            id: `assistantship_${assistantshipKey}`,
            value: {
              id: `subject`,
              value: assistantshipSubject,
            },
          })
        "
        type="text"
        :class="`uc-input-style w-icon-left`"
        placeholder="Curso"
      />
      <i class="uc-icon">account_box</i>
    </div>
    <div class="uc-form-group">
      <v-menu
        ref="menu"
        :close-on-content-click="assistantshipDate.length > 1"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <div>
            <label :for="`assistantshipDate_${assistantshipKey}`"
              >Periodo</label
            >
            <input
              :class="`uc-input-style w-icon-left`"
              v-tooltip="
                `Si aún continúas desepeñando esto, sólo marca el periodo de comienzo`
              "
              :id="`assistantshipDate_${assistantshipKey}`"
              readonly
              type="text"
              :placeholder="assistantshipDatePlaceholder"
              v-bind="attrs"
              v-on="on"
            />
            <i class="uc-icon">account_box</i>
          </div>
        </template>
        <v-date-picker
          :max="new Date().toISOString().substr(0, 10)"
          @input="
            setFieldArray({
              field: `assistantships`,
              id: `assistantship_${assistantshipKey}`,
              value: {
                id: `date`,
                value: assistantshipDate,
              },
            })
          "
          no-title
          range
          type="month"
          v-model="assistantshipDate"
        ></v-date-picker>
      </v-menu>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { utils } from "@/mixins/utils.js";
import { mapMutations } from "vuex";

export default {
  props: {
    assistantshipKey: { type: Number, default: 0 },
    assistantshipContent: Array,
  },
  mixins: [utils],
  data() {
    return {
      assistantshipSubject: null,
      assistantshipTeacher: null,
      assistantshipDate: [],
      assistantshipDatePlaceholder: "Desde - Hasta",
    };
  },
  created() {
    for (var i in this.assistantshipContent) {
      const element = this.assistantshipContent[i];
      this[`assistantship${this.capitalize(element.id)}`] = element.value;
    }
  },
  methods: {
    ...mapMutations("CVForm", ["setFieldArray"]),
  },
  watch: {
    assistantshipDate() {
      switch (this.assistantshipDate.length) {
        case 1:
          this.assistantshipDatePlaceholder = `Desde ${moment(
            this.assistantshipDate[0] + "-01"
          ).format("MMM/Y")} - Actualidad`;
          break;
        case 2:
          this.assistantshipDatePlaceholder = `Desde ${moment(
            this.assistantshipDate[0] + "-01"
          ).format("MMM/Y")} - Hasta ${moment(
            this.assistantshipDate[1] + "-01"
          ).format("MMM/Y")}`;
          break;
        default:
          this.assistantshipDatePlaceholder = null;
      }
    },
  },
};
</script>
